export enum ActionTypes {
  SEND_OTP_REQUEST = "SEND_OTP_REQUEST",
  OTP_REQUEST_SUCCESS = "OTP_REQUEST_SUCCESS",
  OTP_REQUEST_FAIL = "OTP_REQUEST_FAIL",
  VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL",
  VERIFY_CUSTOMER_SESSION = "VERIFY_CUSTOMER_SESSION",
  SET_CUSTOMER_SESSION = "SET_CUSTOMER_SESSION",
  EDIT_MAIL = "EDIT_MAIL",
  LOGOUT = "LOGOUT",
  ERROR_CUSTOMER_SESSION="ERROR_CUSTOMER_SESSION"
}
