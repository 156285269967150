import { APICore } from "./apiCore";

const api = new APICore();

function fetchTag(params: { page: string, limit: string, sortBy: string, sort: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/tag/all`;
    console.log(baseUrl)
    return api.get(`${baseUrl}`, params);
}

function createTag(body: any) {
    const baseUrl = '/tag';
    return api.create(`${baseUrl}`, body);
}

function fetchSingleTag(params: any) {
    const baseUrl = `/tag/${params}`;
    return api.get(`${baseUrl}`, {});
}

function updateTag(data: any,tagId:any) {
    const baseUrl = `/tag/${tagId}`;
    return api.update(`${baseUrl}`, data);
}

function getTagType() {
    const baseUrl = `/tag/tagType`;
    return api.get(`${baseUrl}`,"");
}

export {getTagType, fetchTag, createTag, fetchSingleTag, updateTag }