import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchAllFiles, updloadFiles, downFileById, downloadFileByPath, fetchTags, deleteFileById,fetchFilesById } from "helpers"
import { fileApiError, fileApiResponse, fileDeleteResponse, fileDownloadResponse, fileUploadResponse } from "./actions";
import { ActionTypes } from "./constants";

function* files(params: any): SagaIterator {
    try {
        const response = yield call(fetchAllFiles, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort });
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.FETCH_FILES, filesData));
    } catch (e: any) {
        yield put(fileApiError(ActionTypes.FETCH_FILES, e))
    }
}

function* filesById(params: any): SagaIterator {
    try {
        const response = yield call(fetchFilesById, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort,id: params.payload.data.id,type:params.payload.data.type});
        const filesData = response.data;
        yield put(fileApiResponse(ActionTypes.FILES_BY_ID, filesData));
    } catch (e: any) {
        yield put(fileApiError(ActionTypes.FILES_BY_ID, e))
    }
}


function* _uploadFile(params: any): SagaIterator {
    try {
        const response = yield call(updloadFiles, { formData: params.payload.data.formData })
        const filesData = response.data;
        console.log(filesData);
        
        yield put(fileUploadResponse(ActionTypes.UPLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileUploadResponse(ActionTypes.UPLOAD_FILE_FAIL, err))
    }
}

function* _downloadById(params: any): SagaIterator {
    try {
        const response = yield call(downFileById, { id: params.payload.data.id })
        const filesData = response.data;
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_FAIL, err))
    }
}

function* _downloadByPath(params: any): SagaIterator {
    try {
        const response = yield call(downloadFileByPath, { path: params.payload.data.path })
        const filesData = response.data;
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_SUCCESS, filesData))
    } catch (err) {
        yield put(fileDownloadResponse(ActionTypes.DOWNLOAD_FILE_FAIL, err))
    }
}

function* _deleteById(params: any): SagaIterator {
    try {
        const response = yield call(deleteFileById, { id: params.payload.data.id });
        const filesData = response.data;
        yield put(fileDeleteResponse(ActionTypes.DELETE_SUCCESS, filesData))
    } catch (e: any) {
        yield put(fileDeleteResponse(ActionTypes.DELETE_FAIL, e))
    }
}

function* _tags(): SagaIterator {
    try {
        const response = yield call(fetchTags);
        const tagData = response.data;
        yield put(fileApiResponse(ActionTypes.FETCH_TAG, tagData))
    } catch (err) {
        yield put(fileApiError(ActionTypes.SET_TAG, err))
    }
}


function* watchFiles() {
    yield takeEvery(ActionTypes.FETCH_FILES, files);
    yield takeEvery(ActionTypes.DOWNLOAD_FILE_BY_ID, _downloadById);
    yield takeEvery(ActionTypes.DOWNLOAD_FILE_BY_PATH, _downloadByPath)
    yield takeEvery(ActionTypes.UPLOAD_FILE, _uploadFile)
    yield takeEvery(ActionTypes.FETCH_TAG, _tags)
    yield takeEvery(ActionTypes.DELETE_FILE_BY_ID, _deleteById)
    yield takeEvery(ActionTypes.FILES_BY_ID,filesById)
}

export default watchFiles;