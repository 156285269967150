// import { APICore } from 'helpers/api/apiCore';
import { ActionTypes as AuthActionTypes } from "./constants";
import { Task, TaskState } from "../types/DataTypes";

// const api = new APICore();

const INIT_STATE = {
  loading: true,
  task: {},
  count: null,
  singleTask: {},
  error: {},
  status: {},
  priority: {},
  success: false
};

type AuthActionType = {
  type:
  | AuthActionTypes.SET_TASK
  | AuthActionTypes.SET_TASK_COUNT
  | AuthActionTypes.CREATE_TASK
  | AuthActionTypes.FETCH_TASK
  | AuthActionTypes.SET_ERROR
  | AuthActionTypes.FETCH_SINGLE_TASKS
  | AuthActionTypes.SET_SINGLE_TASK
  | AuthActionTypes.ADD_TASK_SUCCESS
  | AuthActionTypes.ADD_TASK_FAIL
  | AuthActionTypes.UPDATE_TASK_SUCCESS
  | AuthActionTypes.UPDATE_TASK_FAIL
  | AuthActionTypes.SET_TASK_STATUS
  | AuthActionTypes.SET_TASK_PRIORITY
  | AuthActionTypes.RESET_TASK_STATE
  payload: {
    actionType?: string;
    data?: Task | {};
    error?: string;
  };
};

const TaskReducer = (state: TaskState = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.SET_TASK:
      return { ...state, task: action.payload.data, singleTask: {}, loading: false, success: false };
    case AuthActionTypes.SET_TASK_COUNT:
      return { ...state, count: action.payload.data, singleTask: {}, loading: false, success: false };
    case AuthActionTypes.SET_SINGLE_TASK:
      return { ...state, singleTask: action.payload.data, loading: false, success: false };
    case AuthActionTypes.ADD_TASK_SUCCESS:
      return { ...state, success: true, error: {} }
    case AuthActionTypes.ADD_TASK_FAIL:
      return { ...state, success: false, error: action.payload.error }
    case AuthActionTypes.UPDATE_TASK_SUCCESS:
      return { ...state, success: true, error: {} }
    case AuthActionTypes.UPDATE_TASK_FAIL:
      return { ...state, success: false, error: action.payload.error }
    case AuthActionTypes.SET_TASK_STATUS:
      return { ...state, status: action.payload.data, loading: false, success: false };
    case AuthActionTypes.SET_TASK_PRIORITY:
      return { ...state, priority: action.payload.data, loading: false, success: false };
    case AuthActionTypes.SET_ERROR:
      return { ...state, task: {}, singleTask: {}, error: action.payload.error, loading: false };
    case AuthActionTypes.RESET_TASK_STATE:
      return { ...INIT_STATE }
    default:
      return { ...state };
  }
};

export default TaskReducer;
