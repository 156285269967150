import { ActionTypes as AuthActionTypes } from "./constants";
// import { Notification } from "../types/DataTypes";

const INIT_STATE = {
    loading: true,
    notification: {},
    error: {},
    success: false
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_NOTIFICATIONS
    | AuthActionTypes.SET_NOTIF_ERROR
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    }
}

const NotificationReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_NOTIFICATIONS:
            console.log(action.payload.data.response, "action.payload.data")
            return { ...state, notification: action.payload.data, loading: false, success: true };

        case AuthActionTypes.SET_NOTIF_ERROR:
            return { ...state, notification: {}, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default NotificationReducer;