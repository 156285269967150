import { APICore } from "./apiCore";

const api = new APICore();
//
function fetchNotification(params: { userId: any, userType: any, typeId?: string }) {
    // const baseUrl = `/notification/notif/all?user_id=` + params.userId + `&user_type=` + params.userType + `&type_id=` + params.typeId ? params.typeId : '';
    const url = `/notification/notif/all?user_id=${params.userId}&user_type=${params.userType}&type_id=${params.typeId ? params.typeId : ''}`
    console.log("baseUrl", url)
    return api.get(`${url}`, "");
}


export { fetchNotification }