import { ActionTypes } from './constants';

export type RoleActionType = {
    type:
    | ActionTypes.CREATE_ROLE
    | ActionTypes.FETCH_ROLE
    | ActionTypes.SET_ROLE
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_ROLE
    | ActionTypes.FETCH_SINGLE_ROLE
    | ActionTypes.SET_SINGLE_ROLE
    | ActionTypes.UPDATE_ROLE_SUCCESS
    | ActionTypes.UPDATE_ROLE_FAIL
    | ActionTypes.ADD_ROLE_SUCCESS
    | ActionTypes.ADD_ROLE_FAIL
    | ActionTypes.FETCH_PRIVILEGES
    | ActionTypes.SET_PRIVILEGES
    payload: {} | string;
}

//dispatch action to get all roles
export const getRole = (): RoleActionType => ({
    type: ActionTypes.FETCH_ROLE,
    payload: {}
})


//dispatch action to get single role
export const getSingleRole = (id: any): RoleActionType => ({
    type: ActionTypes.FETCH_SINGLE_ROLE,
    payload: { id }
})

//dispatch action to create role
export const createRole = (data: any): RoleActionType => ({
    type: ActionTypes.CREATE_ROLE,
    payload: { data }
})

//dispatch action to update role
export const updateRole = (data: any): RoleActionType => ({
    type: ActionTypes.UPDATE_ROLE,
    payload: { data }
})


export const getPrivileges = (): RoleActionType => ({
    type: ActionTypes.FETCH_PRIVILEGES,
    payload: {}
})

//handle response after saga
export const roleApiResponse = (actionType: string, data: any): RoleActionType => {
    switch (actionType) {
        case ActionTypes.FETCH_ROLE:
            return {
                type: ActionTypes.SET_ROLE,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_ROLE:
            return {
                type: ActionTypes.SET_SINGLE_ROLE,
                payload: { data }
            }
        case ActionTypes.FETCH_PRIVILEGES:
            return {
                type: ActionTypes.SET_PRIVILEGES,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };

    }
}


//this action will be executed when role updated successfully.
export const roleUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_ROLE_SUCCESS,
        payload: { data }
    }
}

//this action will be executed when there is error updating role
export const roleUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_ROLE_FAIL,
        payload: { error }
    }
}


//this action will be executed when role added successfully
export const roleAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_ROLE_SUCCESS,
        payload: { data }
    }
}


//this action will be executed when there is error while adding role
export const roleAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_ROLE_FAIL,
        payload: { error }
    }
}


//handle other error after api response
export const roleApiError = (actionType: string, error: any) => ({
    type: ActionTypes.SET_ERROR,
    payload: { error }
})