export enum ActionTypes{
    FETCH_ALL_TAG="FETCH_ALL_TAG",
    FETCH_SINGLE_TAG="FETCH_SINGLE_TAG",
    UPDATE_TAG="UPDATE_TAG",
    CREATE_TAG="CREATE_TAG",

    SAVE_TAG="SAVE_TAG",
    SET_TAG="SET_TAG",
    SET_SINGLE_TAG="SET_SINGLE_TAG",
    SET_ERROR="SET_ERROR",

    GET_TAG_TYPE="GET_TAG_TYPE",
    SET_TAG_TYPE="SET_TAG_TYPE"




}