import { ActionTypes } from './constants';


const INIT_STATE = {
    loading: true,
    project: {},
    createdProject: {},
    createdTask: {},
    projectTask: {},
    singleProject: {},
    singleTask: {},
    taskError: {},
    projectError: {},
    assignedTask: {},
    completeProject: {},
    countAssignedTask: null,
    myProject: {},
    taskCount: {},
    projectByMonth: {},
    projectWithValue: {},
    projectWithOngoing: {},
    projectWithCompleted: {},
    funnelData: {},
    projectSuccess: false,
    taskSuccess: false,
    success: false,
    projectAddSuccess: false,
    projectStatusList: {
        type: {},
        values: []
    }
}


type ProjectActionType = {
    type:
    | ActionTypes.SET_PROJECT
    | ActionTypes.SET_SINGLE_PROJECT_TASK
    | ActionTypes.SET_ERROR
    | ActionTypes.ADD_PROJECT_SUCCESS
    | ActionTypes.ADD_PROJECT_FAIL
    | ActionTypes.ADD_PROJECT_TASK_SUCCESS
    | ActionTypes.ADD_PROJECT_TASK_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.SET_SINGLE_PROJECT
    | ActionTypes.FETCH_SINGLE_TASK
    | ActionTypes.SET_SINGLE_TASK
    | ActionTypes.SET_ASSIGNED_TASK
    | ActionTypes.SET_ASSIGNED_TASK_COUNT
    | ActionTypes.FETCH_CUSTOMER_PROJECT
    | ActionTypes.SET_CUSTOMER_PROJECT
    | ActionTypes.UPDATE_PROJECT_SUCCESS
    | ActionTypes.UPDATE_PROJECT_FAIL
    | ActionTypes.SET_TASK_COUNT_BASED_ON_USER
    | ActionTypes.SET_PROJECT_STATUS
    | ActionTypes.SET_PROJECT_MONTH_DATA
    | ActionTypes.SET_COMPLETE_PROJECT
    | ActionTypes.SET_PROJECTS_WITH_VALUE
    | ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.SET_FUNNEL_DATA
    | ActionTypes.CLEAR_UPDATE_SUCCESS
    | ActionTypes.CLEAR_CREATED_STATE
    payload: {
        actionType?: string;
        data?: {} | any;
        error?: string;
    }
}


const ProjectTaskReducer = (state: any = INIT_STATE, action: ProjectActionType) => {
    switch (action.type) {
        case ActionTypes.SET_PROJECT:
            return {
                ...state, project: action.payload.data, projectTask: {}, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, projectAddSuccess: false
            }
        case ActionTypes.SET_PROJECT_STATUS:
            return {
                ...state, projectStatusList: action.payload.data?.data, projectTask: {}, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_ASSIGNED_TASK:
            return {
                ...state, assignedTask: action.payload.data, projectTask: {}, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_ASSIGNED_TASK_COUNT:
            return {
                ...state, countAssignedTask: action.payload.data, projectTask: {}, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_CUSTOMER_PROJECT:
            return {
                ...state, myProject: action.payload.data, projectTask: {}, loading: false, taskSuccess: false,
                projectSuccess: false, taskError: {}, projectError: {},
            }
        case ActionTypes.SET_SINGLE_PROJECT_TASK:
            return {
                ...state, projectTask: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_SINGLE_PROJECT:
            return {
                ...state, singleProject: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_SINGLE_TASK:
            return {
                ...state, singleTask: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {},
            }
        case ActionTypes.SET_TASK_COUNT_BASED_ON_USER:
            return {
                ...state, taskCount: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_PROJECT_MONTH_DATA:
            return {
                ...state, projectByMonth: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_COMPLETE_PROJECT:
            return {
                ...state, completeProject: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_PROJECTS_WITH_VALUE:
            return {
                ...state, projectWithValue: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE:
            return {
                ...state, projectWithOngoing: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE:
            return {
                ...state, projectWithCompleted: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.SET_FUNNEL_DATA:
            return {
                ...state, funnelData: action.payload.data, loading: false, taskSuccess: false, projectSuccess: false, taskError: {},
                projectError: {}, singleTask: {}
            }
        case ActionTypes.CLEAR_UPDATE_SUCCESS:
            return { ...state, projectSuccess: false, projectAddSuccess: false, projectError: {}, taskError: {} }
        case ActionTypes.CLEAR_CREATED_STATE:
            return { ...state, projectSuccess: false, projectAddSuccess: false, projectError: {}, taskError: {}, createdProject: {}, singleProject: {} }
        case ActionTypes.ADD_PROJECT_SUCCESS:
            return { ...state, projectError: {}, createdProject: action.payload.data, projectAddSuccess: true }
        case ActionTypes.UPDATE_PROJECT_SUCCESS:
            return { ...state, projectSuccess: true, projectError: {} }
        case ActionTypes.UPDATE_PROJECT_FAIL:
            return { ...state, projectSuccess: false, projectError: action.payload.data }
        case ActionTypes.ADD_PROJECT_FAIL:
            return { ...state, projectSuccess: false, projectError: action.payload.error }
        case ActionTypes.ADD_PROJECT_TASK_SUCCESS:
            return { ...state, taskSuccess: true, taskError: {}, createdTask: action.payload.data }
        case ActionTypes.ADD_PROJECT_TASK_FAIL:
            return { ...state, taskSuccess: false, taskError: action.payload.error }
        case ActionTypes.UPDATE_PROJECT_TASK_SUCCESS:
            return { ...state, taskSuccess: true, taskError: {} }
        case ActionTypes.UPDATE_PROJECT_TASK_FAIL:
            return { ...state, taskSuccess: false, taskError: action.payload.error }
        case ActionTypes.SET_ERROR:
            return { ...state, project: {}, projectTask: {}, projectError: action.payload.error, taskError: action.payload.error, taskSuccess: false, projectSuccess: false }
        default:
            return { ...state }
    }
}

export default ProjectTaskReducer;