import { ActionTypes as AuthActionTypes } from "./constants";
import { VendorResponse } from "redux/types/DataTypes";

const INIT_STATE = {
    loading: true,
    vendor: {},
    singleVendor: {},
    error: {},
    success: false,
    newVendor: {},
    updateSuccess: false,
    vendorCount: {}
}

type AuthActionType = {
    type:
    | AuthActionTypes.SET_VENDOR
    | AuthActionTypes.CREATE_VENDOR
    | AuthActionTypes.FETCH_VENDOR
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_VENDOR
    | AuthActionTypes.SET_SINGLE_VENDOR
    | AuthActionTypes.ADD_VENDOR_SUCCESS
    | AuthActionTypes.ADD_VENDOR_FAIL
    | AuthActionTypes.UPDATE_VENDOR_SUCCESS
    | AuthActionTypes.UPDATE_VENDOR_FAIL
    | AuthActionTypes.SET_DASHBOARD_VENDOR_DATA
    | AuthActionTypes.RESET_VENDOR

    payload: {
        actionType?: string;
        data?: VendorResponse | {};
        error?: string;
    }
}

const VendorReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_VENDOR:
            console.log(action.payload.data);
            return { ...state, vendor: action.payload.data, singleVendor: {}, loading: false, success: false, error: {}, updateSuccess: false, newVendor: {} }
        case AuthActionTypes.SET_SINGLE_VENDOR:
            console.log(action.payload.data);
            return { ...state, singleVendor: action.payload.data, loading: false, success: false, error: {}, updateSuccess: false }
        case AuthActionTypes.SET_DASHBOARD_VENDOR_DATA:
            return { ...state, vendorCount: action.payload.data, loading: false, success: false, error: {}, updateSuccess: false }
        case AuthActionTypes.ADD_VENDOR_SUCCESS:
            return { ...state, success: true, error: {}, newVendor: action.payload.data }
        case AuthActionTypes.ADD_VENDOR_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_VENDOR_SUCCESS:
            return { ...state, success: true, error: {}, updateSuccess: true }
        case AuthActionTypes.UPDATE_VENDOR_FAIL:
            return { ...state, success: false, error: action.payload.error, updateSuccess: false }
        case AuthActionTypes.SET_ERROR:
            return { ...state, vendor: {}, singleVendor: {}, error: action.payload.error }
        case AuthActionTypes.RESET_VENDOR:
            return { ...INIT_STATE }
        default:
            return { ...state }
    }
}

export default VendorReducer;