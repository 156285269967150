export enum ActionTypes {
    SET_VENDOR = "SET_VENDOR",
    SET_ERROR = "SET_ERROR",
    CREATE_VENDOR = "CREATE_VENDOR",
    FETCH_VENDOR = "FETCH_VENDOR",
    UPDATE_VENDOR = "UPDATE_VENDOR",
    FETCH_SINGLE_VENDOR = "FETCH_SINGLE_VENDOR",
    SET_SINGLE_VENDOR = "SET_SINGLE_VENDOR",
    UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS",
    UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL",
    ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS",
    ADD_VENDOR_FAIL = "ADD_VENDOR_FAIL",
    DASHBOARD_VENDOR_DATA = "DASHBOARD_VENDOR_DATA",
    SET_DASHBOARD_VENDOR_DATA = "SET_DASHBOARD_VENDOR_DATA",
    RESET_VENDOR = "RESET_VENDOR"
}


