import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  DefaultLayout,
  VerticalLayout,
  HorizontalLayout,
  DetachedLayout,
  FullLayout,
} from "layouts";
// import Root from './Root';
import { LayoutTypes } from "appConstants";
import { useRedux } from "hooks";
import { UserType } from "redux/types/DataTypes";
import PermissionService from "services/PermissionService";
import PrivateRoute from "./PrivateRoute";


// lazy load all the views

// auth
const Login = React.lazy(() => import("pages/login/Login"));
const Logout = React.lazy(() => import("pages/account/Logout"));
const LockScreen = React.lazy(() => import("pages/account/LockScreen"));
const Dashboard = React.lazy(() => import("pages/dashboard/Ecommerce"));
const VendorSignup = React.lazy(() => import("pages/login/VendorTransporterSignup"));

//Enquiry Component
const Enquiry = React.lazy(() => import("pages/enquiry/Enquiry"));
const EnquiryDetail = React.lazy(() => import("pages/enquiryDetail/Detail"));
const EnquiryAdd = React.lazy(() => import("pages/enquiryDetail/AddEnquiry"));

//Approval Component
const Approval = React.lazy(() => import("pages/approval/Approval"))
const ApprovalAdd = React.lazy(() => import("pages/approval/AddApproval"))
const ApprovalUpdate = React.lazy(() => import("pages/approval/UpdateApproval"))
const ApprovalTypes = React.lazy(() => import("pages/approval-types/ApprovalType"))

//Vendor Component
const Vendor = React.lazy(() => import("pages/vendor/Vendor"));
const AddVendor = React.lazy(() => import("pages/vendor/AddVendor"))
const VendorMap = React.lazy(() => import("pages/vendor/AllVendorMap"))
const VendorDashboard = React.lazy(() => import('pages/dashboard/Ecommerce/VendorDashboard'))

const UpdateVendor = React.lazy(() => import("pages/vendor/UpdateVendor"))

//Chat Component
const Chat = React.lazy(() => import("pages/chat/Chat"));
const ChatList = React.lazy(() => import("pages/chat/ChatList"))
//const Chat = React.lazy(() => import("pages/ChatNew"));

//Transporter Component
const Transporter = React.lazy(() => import("pages/transporter/Transporter"));
const TransporterDetail = React.lazy(() => import("pages/transporter/TransportedDetail"))


//employee Management
const Team = React.lazy(() => import("pages/empolyeeManagement/team/Team"))
const TeamAdd = React.lazy(() => import("pages/empolyeeManagement/team/AddTeam"))
const TeamUpdate = React.lazy(() => import("pages/empolyeeManagement/team/UpdateTeam"))
// heiracry team 
const HierarcyTeam = React.lazy(() => import("pages/empolyeeManagement/team/HierarcyTeam"))

const Employee = React.lazy(() => import("pages/empolyeeManagement/employee/Employee"))
const AddEmployee = React.lazy(() => import("pages/empolyeeManagement/employee/AddEmploy"))
const UpdateEmployee = React.lazy(() => import("pages/empolyeeManagement/employee/UpdateEmployee"))
//Product Component 
const Product = React.lazy(() => import("pages/catlogue/ProductList"));
const ProductDetail = React.lazy(() => import("pages/catlogue/ProductDetail"));


//Form Component
const FormList = React.lazy(() => import("pages/custom-forms/FormList"));
const FormAdd = React.lazy(() => import("pages/custom-forms/AddForm"));



//Task Component
const Task = React.lazy(() => import("pages/task/Task"));
const TaskDetail = React.lazy(() => import("pages/taskDetail/TaskDetail"));
const TaskAdd = React.lazy(() => import("pages/taskDetail/AddTask"));

//Chat Component
// const Chat = React.lazy(() => import("pages/chat/Chat"));


//access-management
const RoleList = React.lazy(() => import("pages/access-management/RoleList"))
const RoleForm = React.lazy(() => import("pages/access-management/role-form"))

//project management
const Project = React.lazy(() => import("pages/project-management/Project"))
const ProjectTaskDetails = React.lazy(() => import("pages/project-management/ProjectTask"));
const GantChart = React.lazy(() => import("pages/project-management/GantChart"))


//customer
const Customer = React.lazy(() => import("pages/customer/Customer"))
const CustomerDetails = React.lazy(() => import("pages/customer/CustomerDetails"))
const CustomerDashboard = React.lazy(() => import("pages/dashboard/Ecommerce/CustomerDashboard"))


//Form
const Form = React.lazy(() => import("pages/enquiryDetail/Form/Form"))


//project task
const ProjectList = React.lazy(() => import("pages/project-task/ProjectList"))
const AssignedTask = React.lazy(() => import("pages/project-task/AssignedTasks"))
const ProjectUpdate = React.lazy(() => import("pages/project-task/ProjectUpdate"));
const JobAdd = React.lazy(() => import("pages/project-management/JobAdd"))
const ProjectGanttChart = React.lazy(() => import("pages/project-management/ProjectGanttChart"))

//Query Component
const Query = React.lazy(() => import("pages/query/Query"));
const Uploader = React.lazy(() => import("components/CustomFileUploader"));


//Files
const Files = React.lazy(() => import("pages/files/Files"))

// vendor search
const VendorSearch = React.lazy(() => import("pages/VendorSearch/searchVendor"))

//tag
const Tag = React.lazy(() => import("pages/tag/Tag"))
const TagDetail = React.lazy(() => import("pages/tagDetail/TagDetail"))

//standard Form
const StandardForm = React.lazy(() => import("pages/enquiryDetail/Form/StandardForm"))

//Leads
const LeadDetail = React.lazy(() => import("pages/query/LeadDetails"))



//Transporter
// const TransportersignUp = React.lazy(() => import("pages/login/TransporterSignUp"))

//IOT
const Iot = React.lazy(() => import("pages/iot/platform-integration"));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  userType?: UserType | UserType[] | '*';
  session?: any
};

const LoadComponent = ({ component: Component, userType }: LoadComponentProps) => {
  const { appSelector } = useRedux();
  const { session } = appSelector((state) => ({
    session: state.Auth.session
  }));
  const permissionService = new PermissionService(session);
  const allowed = permissionService.validateUserType(userType);
  return allowed ?
    <Suspense fallback={loading()}>
      <Component />
    </Suspense> : <h1>Page Not Found</h1>
}

const AllRoutes = () => {
  const { appSelector } = useRedux();


  const { layout, session,  } = appSelector((state) => ({
    layout: state.Layout,
    session: state.Auth.session, 
  }));



  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();
  return useRoutes([
    {
      // public routes
      path: "/",
      element: session?.sessionId && session.userType !== UserType.CUSTOMER ? <Navigate to={"/auth"} /> : <DefaultLayout />,
      children: [
        { path: "/", element: <Navigate to={"/login"} /> },
        { path: "/login", element: <LoadComponent component={Login} userType={'*'} session={session} /> },
        { path: "/lock-screen", element: <LoadComponent component={LockScreen} />},
        { path: "/account/logout", element: <LoadComponent component={Logout} /> },
        { path: "/vendorSignup", element: <LoadComponent component={VendorSignup} />},

      ],
    },
    {
      // private routes
      path: "/auth",
      element: session?.sessionId ? (
        <PrivateRoute roles={"Admin"} component={Layout} />
      ) : (
        <Navigate to={"/"} />
      ),
          children: [
        { path: "/auth", element: <LoadComponent component={session.userType === UserType.EMPLOYEE ? Dashboard : session.userType === UserType.CUSTOMER ? CustomerDashboard : VendorDashboard} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR]} session={session} /> },
        {
          path: "/auth/enquiry",
          element: <LoadComponent component={Enquiry} userType={UserType.EMPLOYEE} session={session} />,
        },
        {
          path: "/auth/myenquiry",
          element: <LoadComponent component={Enquiry} userType={UserType.CUSTOMER} session={session} />,
        },
        {
          path: "/auth/detail",
          element: <LoadComponent component={EnquiryDetail} userType={UserType.EMPLOYEE} session={session} />,
        },
        {
          path: "/auth/enquiryadd",
          element: <LoadComponent component={EnquiryAdd} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} />,
        },
        {
          path: "/auth/vendor",
          element: <LoadComponent component={Vendor} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/addVendor",
          element: <LoadComponent component={AddVendor} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/updateVendor",
          element: <LoadComponent component={UpdateVendor} userType={[UserType.EMPLOYEE,UserType.VENDOR]} session={session} />
        },
        {
          path: "/auth/transporter",
          element: <LoadComponent component={Transporter} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/transporterdetails",
          element: <LoadComponent component={TransporterDetail} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/chat",
          element: <LoadComponent component={Chat} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR]} session={session} />
        },
        {
          path: "/auth/chatList",
          element: <LoadComponent component={ChatList} userType={[UserType.EMPLOYEE, UserType.CUSTOMER, UserType.VENDOR]} session={session} />
        },
        {
          path: "/auth/team",
          element: <LoadComponent component={Team} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/teamadd",
          element: <LoadComponent component={TeamAdd} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/employee",
          element: <LoadComponent component={Employee} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/addemployee",
          element: <LoadComponent component={AddEmployee} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/employeeupdate",
          element: <LoadComponent component={UpdateEmployee} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/teamupdate",
          element: <LoadComponent component={TeamUpdate} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/hierarchy",
          element: <LoadComponent component={HierarcyTeam} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/approval",
          element: <LoadComponent component={Approval} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/approvalAdd",
          element: <LoadComponent component={ApprovalAdd} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/approvalUpdate",
          element: <LoadComponent component={ApprovalUpdate} userType={UserType.EMPLOYEE} session={session} />
        },

        {
          path: "/auth/approvalTypes",
          element: <LoadComponent component={ApprovalTypes} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/product",
          element: <LoadComponent component={Product} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/product-detail",
          element: <LoadComponent component={ProductDetail} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/form",
          element: <LoadComponent component={FormList} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/add-form",
          element: <LoadComponent component={FormAdd} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/task",
          element: <LoadComponent component={Task} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/taskadd",
          element: <LoadComponent component={TaskAdd} userType={UserType.EMPLOYEE} session={session} />,
        },
        {
          path: "/auth/taskupdate",
          element: <LoadComponent component={TaskDetail} userType={UserType.EMPLOYEE} session={session} />,
        },
        {
          path: "/auth/role",
          element: <LoadComponent component={RoleList} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/role/new",
          element: <LoadComponent component={RoleForm} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/role/:id",
          element: <LoadComponent component={RoleForm} userType={UserType.EMPLOYEE} session={session} />
        },
        {
          path: "/auth/project",
          element: <LoadComponent component={Project} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session} />
        },
        {
          path: "/auth/project/task/:id",
          element: <LoadComponent component={ProjectTaskDetails} userType={[UserType.EMPLOYEE, UserType.VENDOR, UserType.TRANSPORTER]} session={session} />
        },
        {
          path: "/auth/customer",
          element: <LoadComponent component={Customer} />
        },
        {
          path: "/auth/customerdetails",
          element: <LoadComponent component={CustomerDetails} userType={[UserType.EMPLOYEE, UserType.CUSTOMER]} session={session}/>
        },
        {
          path: "/auth/projecttask",
          element: <LoadComponent component={ProjectList} />
        },
        {
          path: "/auth/project/list",
          element: <LoadComponent component={JobAdd} />
        },
        {
          path: "/auth/job",
          element: <LoadComponent component={AssignedTask} userType={[UserType.VENDOR, UserType.TRANSPORTER, UserType.EMPLOYEE]} />
        },
        {
          path: "/auth/myproject",
          element: <LoadComponent component={AssignedTask} userType={UserType.CUSTOMER} session={session} />,
        },
        {
          path: "/auth/leads",
          element: <LoadComponent component={Query} />
        },
        {
          path: "/auth/cform",
          element: <LoadComponent component={Form} />
        }, {
          path: "/auth/files",
          element: <LoadComponent component={Files} />
        }, {
          path: "/auth/uploader",
          element: <LoadComponent component={Uploader} />
        },
        {
          path: "/auth/project/update",
          element: <LoadComponent component={ProjectUpdate} />
        },
        {
          path: "/auth/vendors/search",
          element: <LoadComponent component={VendorSearch} />
        },
        {
          path: "/auth/tag",
          element: <LoadComponent component={Tag} />
        },
        {
          path: "/auth/tagDetail",
          element: <LoadComponent component={TagDetail} />
        },
        {
          path: "/auth/project/chart",
          element: <LoadComponent component={GantChart} userType={UserType.EMPLOYEE}/>
        },
        {
          path: "/auth/project/gantt-view",
          element: <LoadComponent component={ProjectGanttChart} />
        },
        {
          path: "/auth/sforms",
          element: <LoadComponent component={StandardForm} />
        },
        {
          path: "/auth/customer/dashboard",
          element: <LoadComponent component={CustomerDashboard} userType={UserType.CUSTOMER} />
        },
        {
          path: "/auth/vendorLocation",
          element: <LoadComponent component={VendorMap} />
        },
        {
          path: "/auth/addlead",
          element: <LoadComponent component={LeadDetail} />
        },
        {
          path: "/auth/iot",
          element: <LoadComponent component={Iot}/>
        },
        {
          path: "/auth/iot-stats",
          element: <LoadComponent component={Iot} userType={UserType.VENDOR}/>
        },
      ],
    },
 
  ]);
};

export { AllRoutes };
