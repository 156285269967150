import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchApproval, fetchCountApprovals, createApproval, fetchSingleApproval, updateApproval, fetchApprovalTypes } from "helpers";
import { approvalApiResponse, approvalApiError, approvalUpdateResponse, approvalUpdateError, approvalAddResponse, approvalAddError } from "./actions";
import { ActionTypes } from './constants';

function* approval(params: any): SagaIterator {
    try {
        console.log("paramsapproval",params)
        const response = yield call(fetchApproval, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, userId: params.payload.data?.userId, enquiryId: params.payload.data?.id, searchBy: params.payload.data?.searchBy });
        const approvalData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL, approvalData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL, error));
    }
}

function* countApprovals(params: any): SagaIterator {
    try {
        const response = yield call(fetchCountApprovals, { user_id: params.payload.data.user_id, status: params.payload.data.status });
        const countApprovalData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_COUNT, countApprovalData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_COUNT, error));
    }
}

function* singleApproval(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleApproval, params.payload.id);
        const singleApproval = response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_SINGLE_APPROVAL, singleApproval))
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_SINGLE_APPROVAL, error));
    }
}

function* approvalTypes(params: any): SagaIterator {
    try {
        const response = yield call(fetchApprovalTypes);
        const approvalTypesData = yield response.data;
        yield put(approvalApiResponse(ActionTypes.FETCH_APPROVAL_TYPES, approvalTypesData));
    } catch (error: any) {
        yield put(approvalApiError(ActionTypes.FETCH_APPROVAL_TYPES, error));
    }
}


function* _updateApproval({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateApproval, { id: payload.data.id, updateBody: payload.data.body })
        const approvalData = response.data;
        console.log("updated successfully", approvalData);
        yield put(approvalUpdateResponse(ActionTypes.UPDATE_APPROVAL, approvalData));
    } catch (e: any) {
        yield put(approvalUpdateError(ActionTypes.UPDATE_APPROVAL, e))
    }
}



function* _createApproval({ payload }: any): SagaIterator {
    try {
        const response = yield call(createApproval, { approval: payload.data });
        const approvalData = response.data;
        console.log("Response in the _createApproval", approvalData);
        yield put(approvalAddResponse(ActionTypes.CREATE_APPROVAL, approvalData));
    } catch (error: any) {
        yield put(approvalAddError(ActionTypes.CREATE_APPROVAL, error));
    }
}

export function* watchApproval() {
    yield takeEvery(ActionTypes.FETCH_APPROVAL, approval);
    yield takeEvery(ActionTypes.FETCH_APPROVAL_COUNT, countApprovals);
    yield takeEvery(ActionTypes.CREATE_APPROVAL, _createApproval);
    yield takeEvery(ActionTypes.UPDATE_APPROVAL, _updateApproval);
    yield takeEvery(ActionTypes.FETCH_SINGLE_APPROVAL, singleApproval);
    yield takeEvery(ActionTypes.FETCH_APPROVAL_TYPES, approvalTypes)
}

export default watchApproval;
