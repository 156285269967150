export enum ActionTypes {
    SET_PROJECT = "SET_PROJECT",
    SET_ERROR = "SET_ERROR",
    CREATE_PROJECT = "CREATE_PROJECT",
    FETCH_PROJECT = "FETCH_PROJECT",
    UPDATE_PROJECT = "UPDATE_PROJECT",
    FETCH_SINGLE_PROJECT = "FETCH_SINGLE_PROJECT",
    SET_SINGLE_PROJECT = "SET_SINGLE_PROJECT",
    UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS",
    UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL",
    ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS",
    ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL",
    SET_PROJECT_TASK = "SET_PROJECT_TASK",
    CREATE_PROJECT_TASK = "CREATE_PROJECT_TASK",
    FETCH_SINGLE_PROJECT_TASK = "FETCH_SINGLE_PROJECT_TASK",
    SET_SINGLE_PROJECT_TASK = "SET_SINGLE_PROJECT_TASK",
    UPDATE_PROJECT_TASK = "UPDATE_PROJECT_TASK",
    UPDATE_PROJECT_TASK_SUCCESS = "UPDATE_PROJECT_TASK_SUCCESS",
    UPDATE_PROJECT_TASK_FAIL = "UPDATE_PROJECT_TASK_FAIL",
    ADD_PROJECT_TASK_SUCCESS = "ADD_PROJECT_TASK_SUCCESS",
    ADD_PROJECT_TASK_FAIL = "ADD_PROJECT_TASK_FAIL",
    FETCH_SINGLE_TASK = "FETCH_SINGLE_TASK",
    SET_SINGLE_TASK = "SET_SINGLE_TASK",
    FETCH_ASSIGNED_TASK = "FETCH_ASSIGNED_TASK",
    SET_ASSIGNED_TASK = "SET_ASSIGNED_TASK",
    FETCH_ASSIGNED_TASK_COUNT = "FETCH_ASSIGNED_TASK_COUNT",
    SET_ASSIGNED_TASK_COUNT = "SET_ASSIGNED_TASK_COUNT",
    FETCH_CUSTOMER_PROJECT = "FETCH_CUSTOMER_PROJECT",
    SET_CUSTOMER_PROJECT = "SET_CUSTOMER_PROJECT",
    FETCH_TASK_COUNT_BASED_ON_USER = "FETCH_TASK_COUNT_BASED_ON_USER",
    SET_TASK_COUNT_BASED_ON_USER = "SET_TASK_COUNT_BASED_ON_USER",
    FETCH_PROJECT_STATUS = "FETCH_PROJECT_STATUS",
    SET_PROJECT_STATUS = "SET_PROJECT_STATUS",
    FETCH_PROJECT_MONTH_DATA = "FETCH_PROJECT_MONTH_DATA",
    SET_PROJECT_MONTH_DATA = "SET_PROJECT_MONTH_DATA",
    FETCH_COMPLETE_PROJECT = "FETCH_COMPLETE_PROJECT",
    SET_COMPLETE_PROJECT = "SET_COMPLETE_PROJECT",
    GET_PROJECTS_WITH_VALUE = "GET_PROJECTS_WITH_VALUE",
    SET_PROJECTS_WITH_VALUE = "SET_PROJECTS_WITH_VALUE",
    GET_ONGOING_PROJECT_WITH_VALUE = "GET_ONGOING_PROJECT_WITH_VALUE",
    SET_ONGOING_PROJECT_WITH_VALUE = "SET_ONGOING_PROJECT_WITH_VALUE",
    GET_COMPLETE_PROJECT_WITH_VALUE = "GET_COMPLETE_PROJECT_WITH_VALUE",
    SET_COMPLETE_PROJECT_WITH_VALUE = "SET_COMPLETE_PROJECT_WITH_VALUE",
    GET_FUNNEL_DATA = "GET_FUNNEL_DATA",
    SET_FUNNEL_DATA = "SET_FUNNEL_DATA",
    CLEAR_UPDATE_SUCCESS = "CLEAR_UPDATE_SUCCESS",
    CLEAR_CREATED_STATE  = "CLEAR_CREATED_STATE",
}


