import { ActionTypes } from "./constants";
import { fetchProjects, createProject, getProjectTasks, createProjectTask, updateProjectTask, getProjectById, getSingleTask, getAssignedTask, getProjectByUserId, fetchCountAssignedTasks, updateProject, getTaskCountBasedOnUser, projectMonthData, fetchProjectWithValue, fetchProjectWithOngoing, fetchProjectWithCompleted, fetchFunnelData } from "helpers"
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { addProjectError, addProjectResponse, addProjectTaskError, addProjectTaskResponse, projectApiError, projectApiResponse, updateProjectResponse, updateProjectTaskError, updateProjectTaskResponse } from "./actions";
import { fetchProjectStatus } from "helpers/api/projectManagement";

function* projects(params: any): SagaIterator {
    try {
        // console.log("params", params)
        const response = yield call(fetchProjects, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data?.filterBy, searchBy: params.payload.data?.searchBy })
        const projectData = response.data;
        yield put(projectApiResponse(!params.payload.data?.filterBy || params.payload.data?.filterBy === 'month' ? ActionTypes.FETCH_PROJECT : ActionTypes.FETCH_COMPLETE_PROJECT, projectData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_PROJECT, error))
    }
}


function* projectValue(params: any): SagaIterator {
    try {
        const response = yield call(fetchProjectWithValue, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter })
        const projectData = response.data;
        yield put(projectApiResponse(ActionTypes.GET_PROJECTS_WITH_VALUE, projectData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.GET_PROJECTS_WITH_VALUE, error))
    }
}

function* projectOnging(params: any): SagaIterator {
    try {
        const response = yield call(fetchProjectWithOngoing, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter })
        const projectData = response.data;
        yield put(projectApiResponse(ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE, projectData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE, error))
    }
}


function* projectCompleted(params: any): SagaIterator {
    try {
        const response = yield call(fetchProjectWithCompleted, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter })
        const projectData = response.data;
        yield put(projectApiResponse(ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE, projectData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE, error))
    }
}


function* funnelData(params: any): SagaIterator {
    try {
        const response = yield call(fetchFunnelData, { filterBy: params.payload.data.filterBy, filter: params.payload.data.filter })
        const projectData = response.data;
        yield put(projectApiResponse(ActionTypes.GET_FUNNEL_DATA, projectData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.GET_FUNNEL_DATA, error))
    }
}


function* countBasedOnUser(params: any): SagaIterator {
    try {
        const response = yield call(getTaskCountBasedOnUser, { user: params.payload.data.user, filterBy: params.payload.data.filterBy, filter: params.payload.data.filter })
        const data = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, data))
    } catch (err: any) {
        yield put(projectApiResponse(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, err))
    }
}


function* _fetchAssignedTask(params: any): SagaIterator {
    try {
        // console.log("params", params)
        const response = yield call(getAssignedTask, { id: params.payload.data.id, page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, userId: params.payload.data.userId, filter: params.payload.data.fiter,searchBy: params.payload.data.searchBy})
        const taskData = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_ASSIGNED_TASK, taskData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_PROJECT, error))
    }
}

function* _countAssignedTask(params: any): SagaIterator {
    try {
        const response = yield call(fetchCountAssignedTasks, { user_id: params.payload.data.user_id, status: params.payload.data.status });
        const countApprovalData = yield response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_ASSIGNED_TASK_COUNT, countApprovalData));
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_ASSIGNED_TASK_COUNT, error));
    }
}

function* _createProject({ payload }: any): SagaIterator {
    try {
        // console.log("payload", payload)
        const response = yield call(createProject, { project: payload.data })
        const projectData = response.data;
        yield put(addProjectResponse(ActionTypes.CREATE_PROJECT, projectData))
    } catch (error: any) {
        yield put(addProjectError(ActionTypes.CREATE_PROJECT, error))
    }
}

function* getSingleProject({ payload }: any): SagaIterator {
    try {
        const response = yield call(getProjectById, payload.data)
        const projectData = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_SINGLE_PROJECT, projectData));
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_SINGLE_PROJECT, error))
    }
}


function* _createProjectTask({ payload }: any): SagaIterator {
    try {
        const response = yield call(createProjectTask, { projectID: payload.data.projectID, task: payload.data.task })
        const projectTaskData = response.data;
        yield put(addProjectTaskResponse(ActionTypes.CREATE_PROJECT_TASK, projectTaskData))
    } catch (error: any) {
        yield put(addProjectTaskError(ActionTypes.CREATE_PROJECT_TASK, error))
    }
}

function* _updateProjectTask({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateProjectTask, { projectID: payload.data.projectID, taskID: payload.data.taskID, task: payload.data.task })
        const projectTaskData = response.data;
        yield put(updateProjectTaskResponse(ActionTypes.UPDATE_PROJECT_TASK_SUCCESS, projectTaskData))
    } catch (error: any) {
        yield put(updateProjectTaskError(ActionTypes.UPDATE_PROJECT_TASK_FAIL, error))
    }
}


function* _updateProject({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateProject, { id: payload.data.projectID, project: payload.data.project })
        const projectTaskData = response.data;
        yield put(updateProjectResponse(ActionTypes.UPDATE_PROJECT_SUCCESS, projectTaskData))
    } catch (error: any) {
        yield put(updateProjectResponse(ActionTypes.UPDATE_PROJECT_FAIL, error))
    }
}




function* _getProjectTask({ payload }: any): SagaIterator {
    try {
        const response = yield call(getProjectTasks, { id: payload.data.id, page: payload.data.page, limit: payload.data.limit, sortBy: payload.data.sortBy, sort: payload.data.sort });
        const singleData = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_SINGLE_PROJECT_TASK, singleData));
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_SINGLE_PROJECT_TASK, error))
    }
}


function* _getProjects({ payload }: any): SagaIterator {
    try {
        const response = yield call(getProjectByUserId, { id: payload.data.id, page: payload.data.page, limit: payload.data.limit, sortBy: payload.data.sortBy, sort: payload.data.sort });
        const singleData = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_CUSTOMER_PROJECT, singleData));
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_CUSTOMER_PROJECT, error))
    }
}

function* _getSingleTask({ payload }: any): SagaIterator {
    try {
        const response = yield call(getSingleTask, { projectID: payload.data.projectID, taskID: payload.data.taskID })
        const singleTaskData = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_SINGLE_TASK, singleTaskData));
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_SINGLE_TASK, error))
    }
}

function* projectStatusList(params: any): SagaIterator {
    try {
        const response = yield call(fetchProjectStatus, { data: params.payload.data });
        const projectStatusData = response.data;
        console.log(projectStatusData);
        yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_STATUS, projectStatusData))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_PROJECT_STATUS, error))
    }
}

function* getProjectMonth(params: any): SagaIterator {
    try {
        const response = yield call(projectMonthData, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const data = response.data;
        yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_MONTH_DATA, data))
    } catch (error: any) {
        yield put(projectApiError(ActionTypes.FETCH_PROJECT_STATUS, error))
    }
}




function* watchProjectTask() {
    yield takeEvery(ActionTypes.FETCH_PROJECT, projects);
    yield takeEvery(ActionTypes.CREATE_PROJECT, _createProject);
    yield takeEvery(ActionTypes.CREATE_PROJECT_TASK, _createProjectTask);
    yield takeEvery(ActionTypes.UPDATE_PROJECT_TASK, _updateProjectTask);
    yield takeEvery(ActionTypes.FETCH_SINGLE_PROJECT_TASK, _getProjectTask);
    yield takeEvery(ActionTypes.FETCH_SINGLE_PROJECT, getSingleProject);
    yield takeEvery(ActionTypes.FETCH_SINGLE_TASK, _getSingleTask)
    yield takeEvery(ActionTypes.FETCH_ASSIGNED_TASK, _fetchAssignedTask);
    yield takeEvery(ActionTypes.FETCH_CUSTOMER_PROJECT, _getProjects);
    yield takeEvery(ActionTypes.FETCH_ASSIGNED_TASK_COUNT, _countAssignedTask);
    yield takeEvery(ActionTypes.UPDATE_PROJECT, _updateProject);
    yield takeEvery(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, countBasedOnUser)
    yield takeEvery(ActionTypes.FETCH_PROJECT_STATUS, projectStatusList);
    yield takeEvery(ActionTypes.FETCH_PROJECT_MONTH_DATA, getProjectMonth)
    yield takeEvery(ActionTypes.GET_PROJECTS_WITH_VALUE, projectValue)
    yield takeEvery(ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE, projectOnging);
    yield takeEvery(ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE, projectCompleted);
    yield takeEvery(ActionTypes.GET_FUNNEL_DATA, funnelData)
}

export default watchProjectTask;