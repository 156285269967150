import { APICore } from "./apiCore";

const api = new APICore();

function fetchEmployee(params: { page: string, limit: string, sortBy: string, sort: string, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    // console.log("sortby", params.sortBy)
    // console.log(params.sortBy);
    const baseUrl = `/employ?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params.searchBy ? params.searchBy : ''}`;
    return api.get(`${baseUrl}`, "");
}

function fetchSingleEmployee(params: any) {
    const baseUrl = `/employ/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createEmployee(body: any) {
    const baseUrl = "/employ";
    return api.create(`${baseUrl}`, body);
}

function updateEmployee(data: any) {

    const baseUrl = `/employ/${data.id}`;
    return api.update(`${baseUrl}`, { employee: data.updateBody });
}

function employeeByTeam(params: { limit: string }) {
    const baseUrl = `/employ/grouplist?limit=${params.limit}`;
    return api.create(`${baseUrl}`, "");
}



export { fetchEmployee, createEmployee, updateEmployee, fetchSingleEmployee, employeeByTeam };