import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    customer: {},
    singleCustomer: {},
    error: {},
    success: false,
    newCustomer: {},
    customerCount: {}
}

type CustomerActionType = {
    type:
    | AuthActionTypes.SET_CUSTOMER
    | AuthActionTypes.CREATE_CUSTOMER
    | AuthActionTypes.FETCH_CUSTOMER
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_CUSTOMER
    | AuthActionTypes.SET_SINGLE_CUSTOMER
    | AuthActionTypes.ADD_CUSTOMER_SUCCESS
    | AuthActionTypes.ADD_CUSTOMER_FAIL
    | AuthActionTypes.UPDATE_CUSTOMER_SUCCESS
    | AuthActionTypes.UPDATE_CUSTOMER_FAIL
    | AuthActionTypes.SET_CUSTOMER_DASHBOARD_DATA
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const CustomerReducer = (state: any = INIT_STATE, action: CustomerActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_CUSTOMER:
            console.log(action.payload.data);
            return { ...state, customer: action.payload.data, singleCustomer: {}, loading: false, success: false, error: {} }
        case AuthActionTypes.SET_SINGLE_CUSTOMER:
            console.log(action.payload.data);
            return { ...state, singleCustomer: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.SET_CUSTOMER_DASHBOARD_DATA:
            return { ...state, customerCount: action.payload.data, loading: false, success: false, error: {} }
        case AuthActionTypes.ADD_CUSTOMER_SUCCESS:
            return { ...state, success: true, error: {}, newCustomer: action.payload.data }
        case AuthActionTypes.ADD_CUSTOMER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.UPDATE_CUSTOMER_SUCCESS:
            return { ...state, success: true, error: {} }
        case AuthActionTypes.UPDATE_CUSTOMER_FAIL:
            return { ...state, success: false, error: action.payload.error }
        case AuthActionTypes.SET_ERROR:
            return { ...state, customer: {}, singleCustomer: {}, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default CustomerReducer;