import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchEmployee, createEmployee, updateEmployee, fetchSingleEmployee, employeeByTeam } from "helpers";
import { employeeAddError, employeeAddResponse, employeeApiError, employeeApiResponse, employeeUpdateResponse, employeUpdateError } from "./actions";
import { ActionTypes } from "./constants";

function* employee(params: any): SagaIterator {
    try {
        const response = yield call(fetchEmployee, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort,searchBy: params.payload.data.searchBy});
        const employeeData = yield response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_EMPLOYEE, employeeData));
    } catch (e: any) {
        yield put(employeeApiError(ActionTypes.FETCH_EMPLOYEE, e))
    }
}

function* singleEmployee(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleEmployee, params.payload.id);
        const singleEmployee = response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_SINGLE_EMPLOYE, singleEmployee))
    } catch (e: any) {
        yield put(employeeApiError(ActionTypes.FETCH_SINGLE_EMPLOYE, e));
    }
}


function* _createEmployee({ payload }: any): SagaIterator {
    try {
        const response = yield call(createEmployee, { employee: payload.data })
        const employeeData = response.data;
        yield put(employeeAddResponse(ActionTypes.CREATE_EMPLOYEE, employeeData))
    } catch (e: any) {
        yield put(employeeAddError(ActionTypes.CREATE_EMPLOYEE, e));
    }
}

function* groupList(params: any): SagaIterator {
    try {
        const response = yield call(employeeByTeam, { limit: params.payload.data.limit });
        const data = response.data;
        yield put(employeeApiResponse(ActionTypes.FETCH_GROUPLIST, data))
    } catch (err) {
        yield put(employeeApiError(ActionTypes.FETCH_GROUPLIST, err));
    }
}

function* _updateEmployee({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateEmployee, { id: payload.data.id, updateBody: payload.data.body })
        const employeeData = response.data;
        // console.log("Updated successfully", employeeData);
        yield put(employeeUpdateResponse(ActionTypes.UPDATE_EMPLOYEE, employeeData));
    } catch (e: any) {
        yield put(employeUpdateError(ActionTypes.UPDATE_EMPLOYEE, e));
    }
}



function* watchEmployee() {
    yield takeEvery(ActionTypes.FETCH_EMPLOYEE, employee);
    yield takeEvery(ActionTypes.CREATE_EMPLOYEE, _createEmployee);
    yield takeEvery(ActionTypes.UPDATE_EMPLOYEE, _updateEmployee);
    yield takeEvery(ActionTypes.FETCH_SINGLE_EMPLOYE, singleEmployee);
    yield takeEvery(ActionTypes.FETCH_GROUPLIST, groupList);

}

export default watchEmployee;