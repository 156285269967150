export enum ActionTypes {
  SET_ERROR = "SET_PRODUCT",
  FETCH_PRODUCT = "FETCH_PRODUCT",
  FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT",
  CREATE_PRODUCT = "CREATE_PRODUCT",
  SET_PRODUCT = "SET_ENQUIRY",
  SET_SINGLE_PRODUCT = "SET_SINGLE_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE",
  RESET_PRODUCT="RESET_PRODUCT",
  FETCH_SKU_WISE = "FETCH_SKU_WISE",
  SET_SKU_WISE = "SET_SKU_WISE"
 
}
