export enum ActionTypes {
    SET_EMPLOYEE = "SET_EMPLOYEE",
    SET_ERROR = "SET_ERROR",
    CREATE_EMPLOYEE = "CREATE_EMPLOYEE",
    FETCH_EMPLOYEE = "FETCH_EMPLOYEE",
    UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE",
    FETCH_SINGLE_EMPLOYE = "FETCH_SINGLE_EMPLOYEE",
    SET_SINGLE_EMPLOYEE = "SET_SINGLE_EMPLOYEE",
    UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL",
    ADD_EMPLOY_SUCCESS = "ADD_EMPLOY_SUCCESS",
    ADD_EMPLOY_FAIL = "ADD_EMPLOY_FAIL",
    FETCH_GROUPLIST = "FETCH_GROUPLIST",
    SET_GROUPLIST = "SET_GROUPLIST",
}


