import { SagaIterator } from "@redux-saga/core";
// import { APICore } from "helpers/api/apiCore";
import { call, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./constants";
import { sendOtp as sendOtpApi, verifyOtp as verifyOtpApi,verifyCustomerSession } from "helpers";
import {
  otpErrorResponse,
  otpRequestResponse,
  verifyOtpError,
  verifyOtpResponse,
  verifyCustomerSessionRespose,
  verifyCustomerSessionError
} from "./actions";

type LoginData = {
  payload: {
    email: string;
    key: string;
    otp: string;
  };
  type: string;
};

// const api = new APICore();

function* sendOtpRequest({
  payload: { email },
  type,
}: LoginData): SagaIterator {
  try {
    const response = yield call(sendOtpApi, { email });
    const res = yield response.data;
    yield put(otpRequestResponse(ActionTypes.SEND_OTP_REQUEST, res));
  } catch (e: any) {
    yield put(otpErrorResponse(ActionTypes.SEND_OTP_REQUEST, e));
  }
}
 
//function to verify otp
function* verifyOtpRequest({
  payload: { email, key, otp },
  type,
}: LoginData): SagaIterator {
  try {
    const response = yield call(verifyOtpApi, { email, key, otp });
    const res = yield response.data;
    // console.log("response in the verifyOtpRequest", res);
    yield put(verifyOtpResponse(ActionTypes.VERIFY_OTP_REQUEST, res));
  } catch (e: any) {
    yield put(verifyOtpError(ActionTypes.VERIFY_OTP_REQUEST, e));
  }
}
//function to verify otp
function* _verifyCustomerSession({
  payload: {sessionId}
}: any): SagaIterator {
  try {
    const response = yield call(verifyCustomerSession,sessionId);
    const res = yield response.data;
    yield put(verifyCustomerSessionRespose(ActionTypes.SET_CUSTOMER_SESSION, res));
  } catch (e: any) {
    yield put(verifyCustomerSessionError(ActionTypes.SET_CUSTOMER_SESSION,e));
  }
}

export function* watchSendOtpRequest() {
  yield takeEvery(ActionTypes.SEND_OTP_REQUEST, sendOtpRequest);
}

export function* watchVerifyOtpRequest() {
  yield takeEvery(ActionTypes.VERIFY_OTP_REQUEST, verifyOtpRequest);
}


export function* watchVerifyCustomerSession() {
  yield takeEvery(ActionTypes.VERIFY_CUSTOMER_SESSION, _verifyCustomerSession);
}