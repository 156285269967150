import { ActionTypes } from "./constants";

export type FormActionType = {
  type:
  | ActionTypes.FETCH_FORM
  | ActionTypes.CREATE_FORM
  | ActionTypes.UPDATE_FORM
  | ActionTypes.SET_FORM
  | ActionTypes.SET_FORM_SUCCESS
  | ActionTypes.ENQUIRY_FORM
  | ActionTypes.FETCH_SINGLE_FORM
  | ActionTypes.SET_SINGLE_FORM
  | ActionTypes.SET_ENQUIRY_FORM
  | ActionTypes.FETCH_ALL_FORM
  | ActionTypes.SET_ALL_FORM
  | ActionTypes.UPDATE_FORM_SUCCESS
  | ActionTypes.SET_ERROR
  | ActionTypes.FETCH_FORM_ANSWER
  | ActionTypes.SET_FORM_ANSWER
  | ActionTypes.SET_FORM_ANSWER_SUCCESS
  payload: {} | string;
};



export const getForm = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_FORM,
  payload: { data },
});

export const getFormAnswer = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_FORM_ANSWER,
  payload: { data },
});

//create product
export const saveForm = (data: any): FormActionType => ({
  type: ActionTypes.CREATE_FORM,
  payload: { data },
});

//update product
export const updateForm = (data: any): FormActionType => ({
  type: ActionTypes.UPDATE_FORM,
  payload: { data },
});

//get enquiry form
export const enquiryForm = (data: any): FormActionType => ({
  type: ActionTypes.ENQUIRY_FORM,
  payload: { data },
});

//get single form
export const singleForm = (id: any): FormActionType => ({
  type: ActionTypes.FETCH_SINGLE_FORM,
  payload: { id },
});

//get all form
export const getAllForm = (data: any): FormActionType => ({
  type: ActionTypes.FETCH_ALL_FORM,
  payload: { data },
});




//enquiry action to  dispatch
export const formApiResponse = (
  actionType: string,
  data: any
): FormActionType => {
  switch (actionType) {
    case ActionTypes.SET_FORM_SUCCESS:
      return {
        type: ActionTypes.SET_FORM_SUCCESS,
        payload: { actionType, data },
      };
    case ActionTypes.SET_FORM:
      return {
        type: ActionTypes.SET_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ALL_FORM:
      return {
        type: ActionTypes.SET_ALL_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_SINGLE_FORM:
      return {
        type: ActionTypes.SET_SINGLE_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.SET_ENQUIRY_FORM:
      return {
        type: ActionTypes.SET_ENQUIRY_FORM,
        payload: { actionType, data },
      };
    case ActionTypes.UPDATE_FORM_SUCCESS:
      return {
        type: ActionTypes.UPDATE_FORM_SUCCESS,
        payload: { actionType, data },
      };
      case ActionTypes.SET_FORM_ANSWER:
      return {
        type: ActionTypes.SET_FORM_ANSWER,
        payload: { actionType, data },
      };
    default:
      return {
        type: ActionTypes.SET_ERROR,
        payload: { actionType, error: "Error While setting data to store ! " },
      };
  }
};

//api response error handling
export const formResponseError = (
  actionType: string,
  error: any
): FormActionType => ({
  type: ActionTypes.SET_ERROR,
  payload: { actionType, error },
});
