export enum ActionTypes {
    FETCH_TRANSPORTER = 'FETCH_TRANSPORTER',
    SET_TRANSPORTER = 'SET_TRANSPORTER',
    SET_ERROR = 'SET_ERROR',
    CREATE_TRANSPORTER = "CREATE_TRANSPORTER",
    UPDATE_TRANSPORTER = "UPDATE_TRANSPORTER",
    FETCH_SINGLE_TRANSPORTER = "FETCH_SINGLE_TRANSPORTER",
    SET_SINGLE_TRANSPORTER = "SET_SINGLE_TRANSPORTER",
    UPDATE_TRANSPORTER_SUCCESS = "UPDATE_TRANSPORTER_SUCCESS",
    UPDATE_TRANSPORTER_FAIL = "UPDATE_TRANSPORTER_FAIL",
    ADD_TRANSPORTER_SUCCESS = "ADD_TRANSPORTER_SUCCESS",
    ADD_TRANSPORTER_FAIL = "ADD_TRANSPORTER_FAIL",

    //! File upload for transporter
    CREATE_TRANSPORTER_WITH_FILES = "CREATE_TRANSPORTER_WITH_FILES",
    // GET_SINGLE_TRANSPORTER_WITH_FILES = "GET_SINGLE_TRANSPORTER_WITH_FILES",
    UPDATE_TRANSPORTER_WITH_FILES = "UPDATE_TRANSPORTER_WITH_FILES"
}