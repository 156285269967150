import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { createQuery, fetchQuery, fetchSingleQuery, updateQuery } from "helpers";
import { ActionTypes } from "./constants";
import { queryApiError, queryApiResponse } from "./actions";

function* query(params: any): SagaIterator {
    try {
        const response = yield call(fetchQuery, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, searchBy: params.payload.data.searchBy });
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_QUERY, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.FETCH_QUERY, e))
    }
}

function* singleQuery(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleQuery, params.payload.data);
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.FETCH_SINGLE_QUERY, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.FETCH_SINGLE_QUERY, e))
    }
}

function* updateSingleQuery(params: any): SagaIterator {
    try {
        console.log("parsam", params)
        const response = yield call(updateQuery, { id: params.payload.data.id, query: params.payload.data.query });
        const queryData = yield response.data;
        yield put(queryApiResponse(ActionTypes.UPDATE_QUERY_SUCCESS, queryData));
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.UPDATE_QUERY_ERROR, e))
    }
}

function* _createQuery({ payload }: any): SagaIterator {
    try {
        const response = yield call(createQuery, { query: payload.data })
        const queryData = response.data;
        yield put(queryApiResponse(ActionTypes.CREATE_QUERY_SUCCESS, queryData))
    } catch (e: any) {
        yield put(queryApiError(ActionTypes.CREATE_QUERY_ERROR, e));
    }
}



function* watchQuery() {
    yield takeEvery(ActionTypes.FETCH_QUERY, query);
    yield takeEvery(ActionTypes.FETCH_SINGLE_QUERY, singleQuery);
    yield takeEvery(ActionTypes.UPDATE_QUERY, updateSingleQuery);
    yield takeEvery(ActionTypes.CREATE_QUERY, _createQuery);
}

export default watchQuery;