import { APICore } from "./apiCore";
const api = new APICore();

function fetchCustomer(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: string, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/customer?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}`;
    return api.get(`${baseUrl}`, "");
}

function fetchCustomerForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/customer/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.get(`${baseUrl}`, "");
}


function fetchSingleCustomer(params: any) {
    const baseUrl = `/customer/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createCustomer(body: any) {
    const baseUrl = "/customer";
    return api.create(`${baseUrl}`, body);
}

function updateCustomer(data: any) {

    const baseUrl = `/customer/${data.id}`;
    return api.update(`${baseUrl}`, { customer: data.updateBody });
}



export { fetchCustomer, fetchSingleCustomer, createCustomer, updateCustomer, fetchCustomerForDasbord };