// import { APICore } from 'helpers/api/apiCore';
import { ActionTypes as AuthActionTypes } from "./constants";
import { Enquiry } from "../types/DataTypes";

// const api = new APICore();

const INIT_STATE = {
  loading: true,
  enquiry: {},
  singleEnquiry: {},
  error: {},
  success: false,
  updateSuccess: false,
  formSuccess: false,
  imagePath: "",
  enquiryStatusesList: [],
  enquiryform: {},
  enquiryData: {},
  createdForm: {},
  chartData: {},
  enquiryCount: {},

};

type AuthActionType = {
  type: AuthActionTypes.SET_ENQUIRY | AuthActionTypes.SET_SINGLE_ENQUIRY | AuthActionTypes.SET_ERROR | AuthActionTypes.UPDATE_ENQUIRY_SUCCESS | AuthActionTypes.CREATE_ENQUIRY_SUCCESS
  | AuthActionTypes.CREATE_ENQUIRY_SUCCESS_FORM | AuthActionTypes.SET_ENQUIRY_STATUSES | AuthActionTypes.SET_ENQUIRY_FORM | AuthActionTypes.RESET | AuthActionTypes.SET_REVENUE_CHART_DATA
  | AuthActionTypes.HARD_RESET | AuthActionTypes.RESET_ENQUIRY_FORM | AuthActionTypes.SET_ENQUIRY_DASHBOARD_DATA
  payload: {
    actionType?: string;
    data?: Enquiry | {};
    error?: string;
  };
};

const EnquiryReducer = (state: any = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.CREATE_ENQUIRY_SUCCESS:
      return { ...state, success: true, loading: false, updateSuccess: false, enquiryData: action.payload.data };
    case AuthActionTypes.CREATE_ENQUIRY_SUCCESS_FORM:
      return { ...state, formSuccess: true, loading: false, createdForm: action.payload.data };
    case AuthActionTypes.SET_ENQUIRY:
      return { ...state, enquiry: action.payload, loading: false, updateSuccess: false, success: false, enquiryData: {} };
    case AuthActionTypes.SET_SINGLE_ENQUIRY:
      return { ...state, singleEnquiry: action.payload, loading: false, updateSuccess: false, success: false, enquiryForm: {} };
    case AuthActionTypes.SET_ENQUIRY_DASHBOARD_DATA:
      return { ...state, enquiryCount: action.payload.data, loading: false, updateSuccess: false, success: false }
    case AuthActionTypes.SET_ENQUIRY_FORM:
      return { ...state, enquiryform: action.payload, loading: false, updateSuccess: false, success: false, createdForm: {} };
    case AuthActionTypes.UPDATE_ENQUIRY_SUCCESS:
      return { ...state, loading: false, updateSuccess: true, success: false };
    case AuthActionTypes.SET_ENQUIRY_STATUSES:
      return { ...state, enquiryStatusesList: action.payload, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.SET_REVENUE_CHART_DATA:
      return { ...state, chartData: action.payload.data, loading: false, updateSuccess: false, success: false }
    case AuthActionTypes.RESET:
      return { ...INIT_STATE, singleEnquiry: state.singleEnquiry, enquiryStatusesList: state.enquiryStatusesList, enquiryForm: {} }
    case AuthActionTypes.RESET_ENQUIRY_FORM:
      return { ...INIT_STATE, singleEnquiry: state.singleEnquiry, enquiryStatusesList: state.enquiryStatusesList, enquiryForm: {}, error: {} }
    case AuthActionTypes.HARD_RESET:
      return { ...INIT_STATE }
    case AuthActionTypes.SET_ERROR:
      return { ...state, enquiry: {}, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default EnquiryReducer;
