import { ActionTypes } from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { getSingleLead, updateLead, createLead, fetchLeadStatus, fetchLeads } from 'helpers'
import { addLeadResponse, leadApiError, leadApiResponse, updateLeadResponse } from "./actions";




function* leads(params: any): SagaIterator {
    try {
        // console.log("params", params)
        const response = yield call(fetchLeads, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort })
        const leadData = response.data;
        yield put(leadApiResponse(ActionTypes.FETCH_LEAD, leadData))
    } catch (error: any) {
        yield put(leadApiError(ActionTypes.FETCH_LEAD, error))
    }
}


function* _createLead({ payload }: any): SagaIterator {
    try {
        const response = yield call(createLead, { lead: payload.data })
        const leadData = response.data;
        yield put(addLeadResponse(ActionTypes.CREATE_LEAD_SUCCESS, leadData))
    } catch (error: any) {
        yield put(addLeadResponse(ActionTypes.CREATE_LEAD_FAIL, error))
    }
}

function* _updateLead({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateLead, { id: payload.data.id, lead: payload.data.lead })
        const leadData = response.data;
        yield put(updateLeadResponse(ActionTypes.UPDATE_LEAD_SUCCESS, leadData))
    } catch (error: any) {
        yield put(updateLeadResponse(ActionTypes.UPDATE_LEAD_FAIL, error))
    }
}


function* _getSingleLead({ payload }: any): SagaIterator {
    try {
        const response = yield call(getSingleLead, payload.id)
        const leadData = response.data;
        yield put(leadApiResponse(ActionTypes.FETCH_SINGLE_LEAD, leadData));
    } catch (error: any) {
        yield put(leadApiError(ActionTypes.FETCH_SINGLE_LEAD, error))
    }
}


function* _fetchLeadStatus(): SagaIterator {
    try {
        const response = yield call(fetchLeadStatus)
        const leadData = response.data;
        yield put(leadApiResponse(ActionTypes.FETCH_LEAD_TAG, leadData));
    } catch (error: any) {
        yield put(leadApiError(ActionTypes.FETCH_LEAD_TAG, error))
    }
}


function* watchLeads() {
    yield takeEvery(ActionTypes.CREATE_LEAD, _createLead)
    yield takeEvery(ActionTypes.FETCH_LEAD_TAG, _fetchLeadStatus)
    yield takeEvery(ActionTypes.UPDATE_LEAD, _updateLead)
    yield takeEvery(ActionTypes.FETCH_SINGLE_LEAD, _getSingleLead)
    yield takeEvery(ActionTypes.FETCH_LEAD, leads)
}
export default watchLeads;