export type Enquiry = {
  payload: {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
  };
};
export type Task = {
  payload: {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
  };
};
export type User = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

export type LoginState = {
  email: string;
  key: string;
  otp: string;
  isValidSession: boolean;
  error: {};
  loading: boolean;
};

export type Response = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    key?: string;
    session?: UserSession;
    enquiryId?:any;
    isValidSession?: boolean;
    userType?: UserType;
    userId: string,
    userName: string,
    email?:string
  };
};

export type VendorResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    vendor?: any[]
  }
}


export type TransporterResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    transporter?: any[]
  }
}

export type TeamResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    team?: any[]
  }
}

export type EmployeeResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    employee?: any[]
    singleEmployee?: any[]
  }
}

export type Form = {
  payload: {
    id: number;
    name: string;
    status: string;
    type: string;
    tag: string;
    description: string;
    customForm: string;
  };
};

export type FormState = {
  loading?: boolean;
  form: Form | {};
}


export type State = {
  loading?: boolean;
  enquiry: Enquiry | {};
  error: {};
};


export type ProductState = {
  loading?: boolean;
  product: Enquiry | {};
}
export type TaskState = {
  loading?: boolean;
  task: Task | {};
  error: {};
};

export type Params = {
  id?: string;
};

export type Approval = {
  payload: {
    id: number;
    enquiry_id: number;
    title: string;
    description: string;
    type: string;
    type_id: number;
    assigned_by: number;
    assigned_to: number;
    status: number;
    comment: string;
  };
};

export type ApprovalState = {
  loading?: boolean;
  approval: Approval | {};
  error: {};
};

export type ApprovalResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    approval?: any[]
  }
}

export enum UserType {
  EMPLOYEE = 'Employee',
  CUSTOMER = 'Customer',
  VENDOR = 'Vendor',
  TRANSPORTER = 'Transporter'
}

export type UserSession = {
  sessionId: string;
  userType: UserType;
  role: string;
  privileges: string[];
  userId: string
}

export type Chat = {
  payload: {
    id: number;
    enquiry_id: number;
    title: string;
    description: string;
    type: string;
    type_id: number;
    assigned_by: number;
    assigned_to: number;
    status: number;
    comment: string;
  };
};

export type ChatState = {
  loading?: boolean;
  chat: Chat | {};
  error: {};
};

export type ChatResponse = {
  success: boolean;
  errorMsg: string;
  successMsg: string;
  errors?: string[];
  response: {
    chat?: any[]
  }
}


export type Notification = {
  success: boolean;
  loading?: boolean;
  errors?: string[];
  response: {
    notification?: any[]
  }
}