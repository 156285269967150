import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchTask, fetchCountTasks, fetchSingleTask, createTask, updateTask, getTaskPriority, getTaskStatus } from "helpers";
import { taskApiResponse, apiTaskResponseError, taskAddResponse, taskAddError, taskUpdateResponse, taskUpdateError } from "./actions";
import { ActionTypes } from "./constants";


function* task(params: any): SagaIterator {
  try {
    const response = yield call(fetchTask, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, userId: params.payload.data?.userId, enquiryId: params.payload.data?.id, searchBy: params.payload.data?.searchBy });
    const taskData = response.data;
    yield put(taskApiResponse(ActionTypes.FETCH_TASK, taskData));
  } catch (error: any) {
    yield put(apiTaskResponseError(ActionTypes.FETCH_TASK, error));
  }
}

function* countTasks(params: any): SagaIterator {
  try {
    const response = yield call(fetchCountTasks, { user_id: params.payload.data.user_id, status: params.payload.data.status });
    const countTaskData = yield response.data;
    yield put(taskApiResponse(ActionTypes.FETCH_TASK_COUNT, countTaskData));
  } catch (error: any) {
    yield put(apiTaskResponseError(ActionTypes.FETCH_TASK_COUNT, error));
  }
}

function* singleTask(params: any): SagaIterator {
  try {
    const response = yield call(fetchSingleTask, params.payload.id);
    const taskData = response.data
    yield put(taskApiResponse(ActionTypes.FETCH_SINGLE_TASKS, taskData))
  } catch (error: any) {
    yield put(apiTaskResponseError(ActionTypes.FETCH_SINGLE_TASKS, error));
  }

}


function* status(): SagaIterator {
  try {
    const response = yield call(getTaskStatus, {});
    const statusData = response.data;
    yield put(taskApiResponse(ActionTypes.FETCH_TASK_STATUS, statusData))
  } catch (error: any) {
    yield put(apiTaskResponseError(ActionTypes.FETCH_TASK_STATUS, error))
  }
}


function* priority(): SagaIterator {
  try {
    const response = yield call(getTaskPriority, {});
    const priorityData = response.data;
    yield put(taskApiResponse(ActionTypes.FETCH_TASK_PRIORITY, priorityData))
  } catch (error: any) {
    yield put(apiTaskResponseError(ActionTypes.FETCH_TASK_PRIORITY, error))
  }
}


function* _createTask({ payload }: any): SagaIterator {

  try {
    const response = yield call(createTask, { task: payload.data });
    const taskData = response.data
    console.log("task added", taskData);
    yield put(taskAddResponse(ActionTypes.CREATE_TASK, taskData))
  } catch (error: any) {
    yield put(taskAddError(ActionTypes.CREATE_TASK, error));
  }
}


function* _updateTask({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateTask, { id: payload.data.id, updateBody: payload.data.body })
    const taskData = response.data;
    console.log("Task updated", taskData);
    yield put(taskUpdateResponse(ActionTypes.UPDATE_TASK, taskData))
  } catch (error: any) {
    yield put(taskUpdateError(ActionTypes.UPDATE_TASK, error))
  }
}

export function* watchTask() {
  yield takeEvery(ActionTypes.FETCH_TASK, task);
  yield takeEvery(ActionTypes.FETCH_TASK_COUNT, countTasks);
  yield takeEvery(ActionTypes.FETCH_SINGLE_TASKS, singleTask);
  yield takeEvery(ActionTypes.CREATE_TASK, _createTask);
  yield takeEvery(ActionTypes.UPDATE_TASK, _updateTask);
  yield takeEvery(ActionTypes.FETCH_TASK_STATUS, status);
  yield takeEvery(ActionTypes.FETCH_TASK_PRIORITY, priority);
}

export default watchTask;
