export enum ActionTypes {
    FETCH_QUERY = "FETCH_QUERY",
    SET_QUERY = "SET_QUERY",
    SET_ERROR = "SET_ERROR",
    FETCH_SINGLE_QUERY = "FETCH_SINGLE_QUERY",
    SET_SINGLE_QUERY = "SET_SINGLE_QUERY",
    SET_SINGLE_ERROR = "SET_SINGLE_ERROR",
    UPDATE_QUERY = "UPDATE_QUERY",
    UPDATE_QUERY_ERROR = "UPDATE_QUERY_ERROR",
    UPDATE_QUERY_SUCCESS = "UPDATE_QUERY_SUCCESS",
    CREATE_QUERY = "CREATE_QUERY",
    CREATE_QUERY_ERROR = "CREATE_QUERY_ERROR",
    CREATE_QUERY_SUCCESS = "CREATE_QUERY_SUCCESS",
    RESET = "RESET",
}
