import { APICore } from "./apiCore";

const api = new APICore();

function fetchForm(params: { page: string, limit: string, sortBy: string, sort: string, type: any, searchBy?: string }) {
  let baseUrl = "";
  if (params.type) {
    baseUrl = `/form?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&type=${params.type}&searchBy=${params.searchBy ? params.searchBy : ''}`;
  } else {
    baseUrl = `/form?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&searchBy=${params.searchBy}`;
  }
  return api.get(`${baseUrl}`, "");
}

function fetchSingleForm(id: any) {
  const baseUrl = `/form/${id}`;
  return api.get(`${baseUrl}`, id);
}

function fetchFormAnswers(params: {id: string, type: string, typeId: string}) {
  const baseUrl = `/form/${params.id}/answer`;
  return api.get(`${baseUrl}`, {type: params.type, typeId: params.typeId});
}


function fetchAllForm(data: any) {
  const baseUrl = `/form`;
  return api.get(`${baseUrl}`, data);
}

function createForm(body: any) {
  const baseUrl = "/form";
  return api.create(`${baseUrl}`, body);
}

function updateForm(body: any) {
  const baseUrl = `/form/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function fetchEnquiryForm(params: {}) {
  const baseUrl = `/form/getForm`;
  return api.get(`${baseUrl}`, params);
}


export { fetchForm, fetchSingleForm, createForm, updateForm, fetchEnquiryForm,fetchAllForm, fetchFormAnswers };
