import { APICore } from "./apiCore";

const api = new APICore();

function fetchRole() {
    const baseUrl = '/role';
    return api.get(`${baseUrl}`, {});
}

function fetchSingleRole(params: any) {
    const baseUrl = `/role/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createRole(body: any) {
    const baseUrl = "/role";
    return api.create(`${baseUrl}`, body);
}

function updateRole(data: any) {
    // console.log("data", data)
    const baseUrl = `/role/${data.id}`;
    return api.update(`${baseUrl}`, data.role);
}

function fetchPrivileges() {
    const baseUrl = `/application/privilege`
    return api.get(`${baseUrl}`, {});
}

export { fetchRole, fetchSingleRole, createRole, updateRole, fetchPrivileges }