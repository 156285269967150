import { ActionTypes } from './constants';

export type ProjectManagementType = {
    type:
    | ActionTypes.CREATE_PROJECT
    | ActionTypes.FETCH_PROJECT
    | ActionTypes.SET_PROJECT
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_PROJECT
    | ActionTypes.UPDATE_PROJECT_SUCCESS
    | ActionTypes.UPDATE_PROJECT_FAIL
    | ActionTypes.ADD_PROJECT_SUCCESS
    | ActionTypes.ADD_PROJECT_FAIL
    | ActionTypes.CREATE_PROJECT_TASK
    | ActionTypes.ADD_PROJECT_TASK_SUCCESS
    | ActionTypes.ADD_PROJECT_TASK_FAIL
    | ActionTypes.UPDATE_PROJECT_TASK
    | ActionTypes.UPDATE_PROJECT_TASK_SUCCESS
    | ActionTypes.UPDATE_PROJECT_TASK_FAIL
    | ActionTypes.FETCH_SINGLE_PROJECT
    | ActionTypes.FETCH_SINGLE_PROJECT_TASK
    | ActionTypes.SET_SINGLE_PROJECT_TASK
    | ActionTypes.SET_PROJECT_TASK
    | ActionTypes.SET_SINGLE_PROJECT
    | ActionTypes.FETCH_SINGLE_TASK
    | ActionTypes.SET_SINGLE_TASK
    | ActionTypes.FETCH_ASSIGNED_TASK
    | ActionTypes.SET_ASSIGNED_TASK
    | ActionTypes.FETCH_ASSIGNED_TASK_COUNT
    | ActionTypes.SET_ASSIGNED_TASK_COUNT
    | ActionTypes.FETCH_CUSTOMER_PROJECT
    | ActionTypes.SET_CUSTOMER_PROJECT
    | ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER
    | ActionTypes.SET_TASK_COUNT_BASED_ON_USER
    | ActionTypes.FETCH_PROJECT_STATUS
    | ActionTypes.SET_PROJECT_STATUS
    | ActionTypes.FETCH_PROJECT_MONTH_DATA
    | ActionTypes.SET_PROJECT_MONTH_DATA
    | ActionTypes.FETCH_COMPLETE_PROJECT
    | ActionTypes.SET_COMPLETE_PROJECT
    | ActionTypes.GET_PROJECTS_WITH_VALUE
    | ActionTypes.SET_PROJECTS_WITH_VALUE
    | ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE
    | ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE
    | ActionTypes.GET_FUNNEL_DATA
    | ActionTypes.SET_FUNNEL_DATA
    | ActionTypes.CLEAR_UPDATE_SUCCESS
    payload: {} | string;
}

//dispatch to get all projects
export const getProjects = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT,
    payload: { data }
})

export const getProjectWithValue = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_PROJECTS_WITH_VALUE,
    payload: { data }
})

export const getProjectWithOngoing = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE,
    payload: { data }
})

export const getProjectWithCompleted = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE,
    payload: { data }
})

export const getFunnelData = (data: any): ProjectManagementType => ({
    type: ActionTypes.GET_FUNNEL_DATA,
    payload: { data }
})

export const clearUpdateSuccess = (): ProjectManagementType => ({
    type: ActionTypes.CLEAR_UPDATE_SUCCESS,
    payload: {}
})

export const clearCreatedProject = () => ({
    type: ActionTypes.CLEAR_CREATED_STATE,
    payload: {}
})




export const getProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_SINGLE_PROJECT_TASK,
    payload: { data }
})

export const createProject = (data: any): ProjectManagementType => ({
    type: ActionTypes.CREATE_PROJECT,
    payload: { data }
})

export const getAssignedTasks = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_ASSIGNED_TASK,
    payload: { data }
})

export const countAssignedTasks = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_ASSIGNED_TASK_COUNT,
    payload: { data }
})

export const createProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.CREATE_PROJECT_TASK,
    payload: { data }
})

export const updateProjectTask = (data: any): ProjectManagementType => ({
    type: ActionTypes.UPDATE_PROJECT_TASK,
    payload: { data }
})

export const updateProject = (data: any): ProjectManagementType => ({
    type: ActionTypes.UPDATE_PROJECT,
    payload: { data }
})

export const getProjectByID = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_SINGLE_PROJECT,
    payload: { data }
})

export const getTaskByID = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_SINGLE_TASK,
    payload: { data }
})

export const getMyProjects = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_CUSTOMER_PROJECT,
    payload: { data }
})

export const getTaskCountBasedOnUser = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER,
    payload: { data }
})

export const getProjectStatus = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_STATUS,
    payload: { data }
})

export const getProjectMonthData = (data: any): ProjectManagementType => ({
    type: ActionTypes.FETCH_PROJECT_MONTH_DATA,
    payload: { data }
})


export const projectApiResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.FETCH_PROJECT:
            return {
                type: ActionTypes.SET_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_PROJECT:
            return {
                type: ActionTypes.SET_SINGLE_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_PROJECT_TASK:
            return {
                type: ActionTypes.SET_SINGLE_PROJECT_TASK,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_TASK:
            return {
                type: ActionTypes.SET_SINGLE_TASK,
                payload: { data }
            }
        case ActionTypes.FETCH_ASSIGNED_TASK:
            return {
                type: ActionTypes.SET_ASSIGNED_TASK,
                payload: { data }
            }
        case ActionTypes.FETCH_ASSIGNED_TASK_COUNT:
            return {
                type: ActionTypes.SET_ASSIGNED_TASK_COUNT,
                payload: { data }
            }
        case ActionTypes.FETCH_CUSTOMER_PROJECT:
            return {
                type: ActionTypes.SET_CUSTOMER_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER:
            return {
                type: ActionTypes.SET_TASK_COUNT_BASED_ON_USER,
                payload: { data }
            }
        case ActionTypes.FETCH_COMPLETE_PROJECT:
            return {
                type: ActionTypes.SET_COMPLETE_PROJECT,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_STATUS:
            return {
                type: ActionTypes.SET_PROJECT_STATUS,
                payload: { data }
            }
        case ActionTypes.FETCH_PROJECT_MONTH_DATA:
            return {
                type: ActionTypes.SET_PROJECT_MONTH_DATA,
                payload: { data }
            }
        case ActionTypes.GET_PROJECTS_WITH_VALUE:
            return {
                type: ActionTypes.SET_PROJECTS_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE:
            return {
                type: ActionTypes.SET_ONGOING_PROJECT_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE:
            return {
                type: ActionTypes.SET_COMPLETE_PROJECT_WITH_VALUE,
                payload: { data }
            }
        case ActionTypes.GET_FUNNEL_DATA:
            return {
                type: ActionTypes.SET_FUNNEL_DATA,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}


export const addProjectResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_PROJECT_SUCCESS,
        payload: { data }
    }
}

export const addProjectError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_PROJECT_FAIL,
        payload: { actionType, error }
    }
}


export const addProjectTaskResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_PROJECT_TASK_SUCCESS,
        payload: { data }
    }
}



export const addProjectTaskError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_PROJECT_TASK_FAIL,
        payload: { actionType, error }
    }
}

export const updateProjectTaskResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_PROJECT_TASK_SUCCESS,
        payload: { actionType, data }
    }
}

export const updateProjectResponse = (actionType: string, data: any): ProjectManagementType => {
    switch (actionType) {
        case ActionTypes.UPDATE_PROJECT_SUCCESS:
            return {
                type: ActionTypes.UPDATE_PROJECT_SUCCESS,
                payload: { actionType, data }
            }
        case ActionTypes.UPDATE_PROJECT_FAIL:
            return {
                type: ActionTypes.UPDATE_PROJECT_FAIL,
                payload: { actionType, data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While updating data to store ! " },
            };
    }
}


export const updateProjectTaskError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_PROJECT_TASK_FAIL,
        payload: { actionType, error }
    }
}

export const projectApiError = (actionType: string, error: any): ProjectManagementType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})