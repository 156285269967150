// import { EmployeeResponse } from "redux/types/DataTypes";
import { ActionTypes } from './constants';

export type QueryActionType = {
    type:
    | ActionTypes.FETCH_QUERY
    | ActionTypes.SET_QUERY
    | ActionTypes.SET_ERROR
    | ActionTypes.FETCH_SINGLE_QUERY
    | ActionTypes.SET_SINGLE_QUERY
    | ActionTypes.SET_SINGLE_ERROR
    | ActionTypes.UPDATE_QUERY
    | ActionTypes.UPDATE_QUERY_ERROR
    | ActionTypes.UPDATE_QUERY_SUCCESS
    | ActionTypes.CREATE_QUERY
    | ActionTypes.CREATE_QUERY_ERROR
    | ActionTypes.CREATE_QUERY_SUCCESS
    payload: {} | string;
}

//dispatch action to get all query
export const getQuery = (data: any): QueryActionType => ({
    type: ActionTypes.FETCH_QUERY,
    payload: { data }
})

//dispatch action to get single query
export const getSingleQuery = (data: any): QueryActionType => ({
    type: ActionTypes.FETCH_SINGLE_QUERY,
    payload: { data }
})

//dispatch action to update query
export const updateQuery = (data: any): QueryActionType => ({
    type: ActionTypes.UPDATE_QUERY,
    payload: { data }
})

export const createQuery = (data: any): QueryActionType => ({
    type: ActionTypes.CREATE_QUERY,
    payload: { data }
})

export const resetQuery = () => ({
    type: ActionTypes.RESET,
    payload: {}
})

//this will trigger in the saga while receiving response of fetching  data
export const queryApiResponse = (actionType: string, data: any): QueryActionType => {
    // type: ActionTypes.SET_EMPLOYEE,
    // payload: { data }
    switch (actionType) {
        case ActionTypes.FETCH_QUERY:
            return {
                type: ActionTypes.SET_QUERY,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_QUERY:
            return {
                type: ActionTypes.SET_SINGLE_QUERY,
                payload: { data }
            }
        case ActionTypes.CREATE_QUERY_SUCCESS:
            return {
                type: ActionTypes.CREATE_QUERY_SUCCESS,
                payload: { data }
            }
        case ActionTypes.UPDATE_QUERY_SUCCESS:
            return {
                type: ActionTypes.UPDATE_QUERY_SUCCESS,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: data },
            };
    }
}



// this action will be executed when there is error while fetching employee info
export const queryApiError = (actionType: string, error: any): QueryActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})