import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchForm, fetchSingleForm, createForm, updateForm, fetchEnquiryForm, fetchAllForm } from "helpers";
import { formApiResponse, formResponseError } from "./actions";
import { ActionTypes } from "./constants";
import { fetchFormAnswers } from "helpers/api/form";


function* form(params: any): SagaIterator {
  try {
    const response = yield call(fetchForm, { page: params.payload.data.page, limit: params.payload.data.limit,sortBy: params.payload.data.sortBy, sort: params.payload.data.sort,type:params.payload.data.type,searchBy:params.payload.data.searchBy });
    const formData = response.data;
    yield put(formApiResponse(ActionTypes.SET_FORM, formData));
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_FORM, error));
  }
}

function* singleForm(params: any): SagaIterator {
  try {
    const response = yield call(fetchSingleForm, params.payload.id);
    const formData = response.data
    yield put(formApiResponse(ActionTypes.SET_SINGLE_FORM, formData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_SINGLE_FORM, error));
  }

}

function* formAnswers(params: any): SagaIterator {
  try {
    const response = yield call(fetchFormAnswers, params.payload.data);
    console.log('aaa', response.data);
    const formData = response.data;
    yield put(formApiResponse(ActionTypes.SET_FORM_ANSWER, formData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_FORM_ANSWER, error));
  }

}

function* _allForm(params: any): SagaIterator {  
  try {
    const response = yield call(fetchAllForm, {type:params.payload.data.type || null,uuid:params.payload.data.uuid || null, page: params.payload.data.page, limit: params.payload.data.limit });
    const formData = response.data
    console.log(formData);
    
    yield put(formApiResponse(ActionTypes.SET_ALL_FORM, formData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_ALL_FORM, error));
  }

}
function* _createForm({ payload }: any): SagaIterator {
  try {
    const response = yield call(createForm, { form: payload.data });
    const formData = response.data
    yield put(formApiResponse(ActionTypes.SET_FORM_SUCCESS, formData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_FORM_SUCCESS, error));
  }
}

function* _updateForm({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateForm, { id: payload.data.id, updateBody: payload.data.body });
    const formData = response.data
    yield put(formApiResponse(ActionTypes.UPDATE_FORM_SUCCESS, formData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.UPDATE_FORM_SUCCESS, error));
  }
}

function* _enquiryForm({ payload }: any): SagaIterator {
  try {
    const response = yield call(fetchEnquiryForm, {productName:payload.data});
    const enquiryFormData = response.data
    yield put(formApiResponse(ActionTypes.SET_ENQUIRY_FORM, enquiryFormData))
  } catch (error: any) {
    yield put(formResponseError(ActionTypes.SET_ENQUIRY_FORM, error));
  }
}



export function* watchForm() {
  yield takeEvery(ActionTypes.FETCH_FORM, form);
  yield takeEvery(ActionTypes.FETCH_SINGLE_FORM, singleForm);
  yield takeEvery(ActionTypes.CREATE_FORM, _createForm);
  yield takeEvery(ActionTypes.ENQUIRY_FORM, _enquiryForm);
  yield takeEvery(ActionTypes.UPDATE_FORM, _updateForm);
  yield takeEvery(ActionTypes.FETCH_ALL_FORM,_allForm);
  yield takeEvery(ActionTypes.FETCH_FORM_ANSWER, formAnswers)
}

export default watchForm;
