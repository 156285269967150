import Routes from "routes/Routes";

// For Default import Saas.scss
import "assets/scss/Saas.scss";
import "assets/global/global.css"
import "assets/global/style.css"
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';

const App = () => {
  return <Routes />;
};

export default App;
