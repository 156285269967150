export enum ActionTypes {
  SET_ENQUIRY = "SET_ENQUIRY",
  SET_SINGLE_ENQUIRY = "SET_SINGLE_ENQUIRY",
  SET_ERROR = "SET_ERROR_ENQUIRY",
  FETCH_ENQUIRY = "FETCH_ENQUIRY",
  FETCH_SINGLE_ENQUIRY = "FETCH_SINGLE_ENQUIRY",
  CREATE_ENQUIRY = "CREATE_ENQUIRY",
  CREATE_ENQUIRY_FORM = "CREATE_ENQUIRY_FORM",
  UPDATE_ENQUIRY = "UPDATE_ENQUIRY",
  UPDATE_ENQUIRY_SUCCESS = "UPDATE_ENQUIRY_SUCCESS",
  CREATE_ENQUIRY_SUCCESS_FORM = "CREATE_ENQUIRY_SUCCESS_FORM",
  CREATE_ENQUIRY_FAILED_IMAGE = "CREATE_ENQUIRY_FAILED_IMAGE",
  FETCH_ENQUIRY_STATUSES = "FETCH_ENQUIRY_STATUSES",
  CREATE_ENQUIRY_SUCCESS = "CREATE_ENQUIRY_SUCCESS",
  SET_ENQUIRY_STATUSES = "SET_ENQUIRY_STATUSES",
  GET_ENQUIRY_FORM = "GET_ENQUIRY_FORM",
  SET_ENQUIRY_FORM = "SET_ENQUIRY_FORM",
  RESET = "RESET",
  GET_REVENUE_CHART_DATA = "GET_REVENUE_CHART_DATA",
  SET_REVENUE_CHART_DATA = "SET_REVENUE_CHART_DATA",
  HARD_RESET = "HARD_RESET",
  RESET_ENQUIRY_FORM = "RESET_ENQUIRY_FORM",
  DASHBOARD_ENQUIRY_DATA = "DASHBOARD_ENQUIRY_DATA",
  SET_ENQUIRY_DASHBOARD_DATA = "SET_ENQUIRY_DASHBOARD_DATA"
}
