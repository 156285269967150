import { ActionTypes } from './constants';

export type NotificationActionType = {
    type:
    | ActionTypes.FETCH_NOTIFICATIONS
    | ActionTypes.SET_NOTIFICATIONS
    | ActionTypes.SET_NOTIF_ERROR

    payload: {} | string;
}

//disptach action to get all teams
export const getNotification = (data: any): NotificationActionType => ({
    type: ActionTypes.FETCH_NOTIFICATIONS,
    payload: { data }
})


//this action will be executed to handle api response
export const notificationApiResponse = (
    actionType: string,
    data: any
) => {



    // FETCH_NOTIFICATION = "FETCH_NOTIFICATION",
    // SET_NOTIFICATION = "SET_NOTIFICATION",
    // SET_ERROR = "SET_ERROR",

    switch (actionType) {
        case ActionTypes.FETCH_NOTIFICATIONS:
            return {
                type: ActionTypes.SET_NOTIFICATIONS,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_NOTIF_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when there is error while adding team
export const notificationApiError = (actionType: string, error: any): NotificationActionType => ({
    type: ActionTypes.SET_NOTIF_ERROR,
    payload: { actionType, error }
})