import { ApprovalResponse } from 'redux/types/DataTypes';
import { ActionTypes } from './constants';

export type ApprovalActionType = {
    type:
    | ActionTypes.CREATE_APPROVAL
    | ActionTypes.FETCH_APPROVAL
    | ActionTypes.FETCH_APPROVAL_COUNT
    | ActionTypes.SET_ERROR
    | ActionTypes.SET_APPROVAL
    | ActionTypes.SET_APPROVAL_COUNT
    | ActionTypes.UPDATE_APPROVAL
    | ActionTypes.FETCH_SINGLE_APPROVAL
    | ActionTypes.SET_SINGLE_APPROVAL
    | ActionTypes.ADD_APPROVAL_SUCCESS
    | ActionTypes.ADD_APPROVAL_FAIL
    | ActionTypes.UPDATE_APPROVAL_SUCCESS
    | ActionTypes.UPDATE_APPROVAL_FAIL
    | ActionTypes.FETCH_APPROVAL_TYPES
    | ActionTypes.SET_APPROVAL_TYPES
    | ActionTypes.RESET_APPROVAL_STATE
    payload: {} | string;
}

//disptach action to get all approvals
export const getApproval = (data: any): ApprovalActionType => ({
    type: ActionTypes.FETCH_APPROVAL,
    payload: { data }
})

//disptach action to get all approvals count
export const countApprovals = (data: any): ApprovalActionType => ({
    type: ActionTypes.FETCH_APPROVAL_COUNT,
    payload: { data }
})

//disptach action to get approval details
export const getSingleApproval = (id: any): ApprovalActionType => ({
    type: ActionTypes.FETCH_SINGLE_APPROVAL,
    payload: { id }
})

//disptach action to update approval
export const updateApproval = (data: any): ApprovalActionType => ({
    type: ActionTypes.UPDATE_APPROVAL,
    payload: { data }
})

//disptach action to create approval
export const createApproval = (data: any): ApprovalActionType => ({
    type: ActionTypes.CREATE_APPROVAL,
    payload: { data }
})

//disptach action to get all approvals
export const getApprovalTypes = (data: any): ApprovalActionType => ({
    type: ActionTypes.FETCH_APPROVAL_TYPES,
    payload: { data }
})

export const resetApproval = () => ({
    type: ActionTypes.RESET_APPROVAL_STATE,
    payload: {}
})

//this action will be executed to handle api response
export const approvalApiResponse = (
    actionType: string,
    data: ApprovalResponse
) => {
    switch (actionType) {
        case ActionTypes.FETCH_APPROVAL:
            return {
                type: ActionTypes.SET_APPROVAL,
                payload: { data }
            }
        case ActionTypes.FETCH_APPROVAL_COUNT:
            return {
                type: ActionTypes.SET_APPROVAL_COUNT,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_APPROVAL:
            return {
                type: ActionTypes.SET_SINGLE_APPROVAL,
                payload: { data }
            }
        case ActionTypes.FETCH_APPROVAL_TYPES:
            return {
                type: ActionTypes.SET_APPROVAL_TYPES,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

// this action will be executed when approval updated successfully
export const approvalUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_APPROVAL_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while updating approval
export const approvalUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_APPROVAL_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when approval is added successfully
export const approvalAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_APPROVAL_SUCCESS,
        payload: { data }
    }
}

// this action will be executed when there is error while adding approval
export const approvalAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_APPROVAL_FAIL,
        payload: { actionType, error }
    }
}

// this action will be executed when there is error while adding approval
export const approvalApiError = (actionType: string, error: any): ApprovalActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})