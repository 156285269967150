import { APICore } from "./apiCore";

const api = new APICore();

function fetchApproval(params: { page: string, limit: string, sortBy: string, sort: string, userId?: string, enquiryId?: any, searchBy?: string }) {
  const baseUrl = `/approval?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.userId}&enquiryId=${params.enquiryId}&searchBy=${params.searchBy}`;
  return api.get(`${baseUrl}`, "");
}

function fetchCountApprovals(params: { user_id: number, status: number }) {
  const baseUrl = `/approval/count?user_id=${params.user_id}&status=${params.status}`;
  return api.get(`${baseUrl}`, "");
}

function fetchSingleApproval(id: any) {
  const baseUrl = `/approval/${id}`;
  return api.get(`${baseUrl}`, id);
}

function createApproval(body: any) {
  const baseUrl = "/approval";
  return api.create(`${baseUrl}`, body);
}

function updateApproval(body: any) {
  const baseUrl = `/approval/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function fetchApprovalTypes() {
  const baseUrl = `/approval/approvalTypes`;
  return api.get(`${baseUrl}`, "");
}

function fetchApprovalTypeList(params: { page: string, limit: string, sortBy: string, sort: string }) {
  const baseUrl = `/approval/approvalTypeList`
  return api.get(`${baseUrl}`, params);
}






export { fetchApproval, fetchCountApprovals, fetchSingleApproval, createApproval, updateApproval, fetchApprovalTypes, fetchApprovalTypeList };
