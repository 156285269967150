import { TransporterResponse } from "redux/types/DataTypes";
import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    transporter: {},
    singleTransporter: null,
    error: {},
    success: false,
    newTransporter: null,
    updateSuccess: false,
    addSuccess: false,
    updatedTransporter: null,
};


type AuthActionType = {
    type:
    | AuthActionTypes.FETCH_TRANSPORTER
    | AuthActionTypes.SET_TRANSPORTER
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.CREATE_TRANSPORTER
    | AuthActionTypes.FETCH_SINGLE_TRANSPORTER
    | AuthActionTypes.SET_SINGLE_TRANSPORTER
    | AuthActionTypes.ADD_TRANSPORTER_SUCCESS
    | AuthActionTypes.ADD_TRANSPORTER_FAIL
    | AuthActionTypes.UPDATE_TRANSPORTER_SUCCESS
    | AuthActionTypes.UPDATE_TRANSPORTER_FAIL
    payload: {
        eractionTyperor?: string;
        data?: TransporterResponse | {};
        error?: string;
    }
}


const TransporterReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_TRANSPORTER:
            // console.log(action.payload.data);
            return { ...state, transporter: action.payload.data, singleTransporter: {}, loading: false, success: false, error: {}, updatedTransporter: null, updateSuccess: false, newTransporter: null };
        case AuthActionTypes.SET_SINGLE_TRANSPORTER:
            return {
                ...state, singleTransporter: action.payload.data, loading: false, success: false, error: {}, updatedTransporter: action.payload.data
            }
        case AuthActionTypes.ADD_TRANSPORTER_SUCCESS:
            return { ...state, success: true, error: {}, newTransporter: action.payload.data, addSuccess: true }
        case AuthActionTypes.ADD_TRANSPORTER_FAIL:
            return { ...state, success: false, error: action.payload.error, addSuccess: false }
        case AuthActionTypes.UPDATE_TRANSPORTER_SUCCESS:
            return { ...state, success: true, addSuccess: false, error: {}, updateSuccess: true }
        case AuthActionTypes.UPDATE_TRANSPORTER_FAIL:
            return { ...state, success: false, error: action.payload.error, updateSuccess: false }
        case AuthActionTypes.SET_ERROR:
            return { ...state, transporter: {}, singleTransporter: {}, error: action.payload.error };
        default:
            return { ...state }
    }
}

export default TransporterReducer;