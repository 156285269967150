import { APICore } from "./apiCore";

const api = new APICore();

function fetchTask(params: { page: string, limit: string, sortBy: string, sort: string, userId?: string, enquiryId?: any, searchBy?: string }) {
  if (params.sortBy.split(' ').length > 1) {
    params.sortBy = params.sortBy.split(' ').join('-')
  }
  const baseUrl = `/task?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&userId=${params.userId}&enquiryId=${params.enquiryId}&searchBy=${params.searchBy ? params.searchBy : ''}`;
  return api.get(`${baseUrl}`, "");
}

function fetchCountTasks(params: { user_id: number, status: number }) {
  const baseUrl = `/task/count?user_id=${params.user_id}&status=${params.status}`;
  return api.get(`${baseUrl}`, "");
}

function fetchSingleTask(params: any) {
  const baseUrl = `/task/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createTask(body: any) {
  const baseUrl = "/task";
  return api.create(`${baseUrl}`, body);
}

function updateTask(body: any) {
  const baseUrl = `/task/${body.id}`;
  return api.update(`${baseUrl}`, body);
}

function getTaskStatus(params: {}) {
  const baseUrl = "/task/status";
  return api.create(`${baseUrl}`, params);
}


function getTaskPriority(params: {}) {
  const baseUrl = "/task/priority";
  return api.create(`${baseUrl}`, params);
}



export { fetchTask, fetchCountTasks, fetchSingleTask, createTask, updateTask, getTaskStatus, getTaskPriority };
