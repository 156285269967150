// import { APICore } from 'helpers/api/apiCore';
import { LoginState, Response, UserSession } from "redux/types/DataTypes";
import { ActionTypes as AuthActionTypes } from "./constants";

// const api = new APICore();

const INIT_STATE = {
  loading: false,
  isVerifyCustomerLoaded: false,
  email: localStorage.getItem('email') || "",
  enquiryId:"",
  key: "",
  otp: "",
  isValidSession: false,
  error: {},
  session: {
    sessionId: localStorage.getItem('token'),
    privileges: [],
    role: '',
    userType: localStorage.getItem('userType'),
    userId: localStorage.getItem('userId'),
    userName: localStorage.getItem('userName'),
  } as Partial<UserSession>
};

type AuthActionType = {
  type:
  | AuthActionTypes.SEND_OTP_REQUEST
  | AuthActionTypes.OTP_REQUEST_SUCCESS
  | AuthActionTypes.OTP_REQUEST_FAIL
  | AuthActionTypes.VERIFY_OTP_REQUEST
  | AuthActionTypes.VERIFY_OTP_SUCCESS
  | AuthActionTypes.SET_CUSTOMER_SESSION
  | AuthActionTypes.VERIFY_OTP_FAIL
  | AuthActionTypes.EDIT_MAIL
  | AuthActionTypes.LOGOUT
  | AuthActionTypes.ERROR_CUSTOMER_SESSION
  payload: {
    actionType?: string;
    data?: Response;
    error?: string;
    email?: string;
  };
};

const AuthReducer = (
  state: LoginState = INIT_STATE,
  action: AuthActionType
) => {
  switch (action.type) {
    case AuthActionTypes.SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        email: action.payload.email,
        error: {},
      };
    case AuthActionTypes.OTP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        key: action.payload?.data?.response?.key,
        error: {}
      };
    case AuthActionTypes.OTP_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case AuthActionTypes.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {}
      };
    case AuthActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isValidSession: action.payload?.data?.response?.isValidSession,
        session: {
          sessionId:  action.payload?.data?.response?.session?.sessionId,
          userType: action.payload?.data?.response?.userType,
          userId: action.payload?.data?.response?.userId,
          userName: action.payload?.data?.response?.userName,
        }
      };

      case AuthActionTypes.SET_CUSTOMER_SESSION:
        console.log(action.payload?.data?.response);
        
        return {
          ...state,
          loading: false,
          isVerifyCustomerLoaded: true,
          isValidSession: action.payload?.data?.response?.isValidSession,
          email: action.payload?.data?.response?.email,
          enquiryId:action.payload?.data?.response.enquiryId,
          session: {
            sessionId:  action.payload?.data?.response?.session,
            userType: action.payload?.data?.response?.userType,
            userId: action.payload?.data?.response?.userId,
            userName: action.payload?.data?.response?.userName,
            
            
          }
        };
      case AuthActionTypes.ERROR_CUSTOMER_SESSION:
        console.log("lallallall");
          return{
            ...state,
            isVerifyCustomerLoaded: true,
          };

    case AuthActionTypes.VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case AuthActionTypes.EDIT_MAIL:
      return {
        ...state,
        key: "",
        email: "",
        isExist: false
      };
    case AuthActionTypes.LOGOUT:
      return {
        key: "",
        email: "",
        otp: "",
        error: {},
        isValidSession: false
      }
    default:
      return { ...state };
  }
};

export default AuthReducer;
