import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
// import { APICore } from 'helpers/api/apiCore';
import { fetchProduct, fetchSingleProduct, createProduct, updateProduct, getProductSkuWise } from "helpers";
import { productApiResponse, productResponseError } from "./actions";
import { ActionTypes } from "./constants";

// const api = new APICore();

function* product(params: any): SagaIterator {
  try {

    const response = yield call(fetchProduct, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort,searchBy: params.payload.data.searchBy});
    const productData = response.data;
    yield put(productApiResponse(ActionTypes.FETCH_PRODUCT, productData));
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.FETCH_PRODUCT, error));
  }
}

function* singleProduct(params: any): SagaIterator {
  try {
    const response = yield call(fetchSingleProduct, params.payload.id);
    const productData = response.data
    console.log("Single query data->", productData);
    yield put(productApiResponse(ActionTypes.FETCH_SINGLE_PRODUCT, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.FETCH_SINGLE_PRODUCT, error));
  }

}
function* _createProduct({ payload }: any): SagaIterator {

  try {
    const response = yield call(createProduct, { product: payload.data });
    const productData = response.data
    console.log(productData);

    yield put(productApiResponse(ActionTypes.CREATE_PRODUCT_SUCCESS, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.CREATE_PRODUCT_SUCCESS, error));
  }

}

function* _updateProduct({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateProduct, { id: payload.data.id, updateBody: payload.data.body });
    const productData = response.data
    yield put(productApiResponse(ActionTypes.UPDATE_PRODUCT, productData))
  } catch (error: any) {
    yield put(productResponseError(ActionTypes.UPDATE_PRODUCT, error));
  }
}



function* _resetProduct({ payload }: any): SagaIterator {
  yield put(productApiResponse(ActionTypes.RESET_PRODUCT, payload))

}

function* skuWise(params: any): SagaIterator {
  try {
    const response = yield call(getProductSkuWise, { limit: params.payload.data.limit, filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter })
    const data = response.data;
    yield put(productApiResponse(ActionTypes.FETCH_SKU_WISE, data))
  } catch (err: any) {
    yield put(productResponseError(ActionTypes.FETCH_SKU_WISE, err))
  }
}



export function* watchProduct() {
  yield takeEvery(ActionTypes.FETCH_PRODUCT, product);
  yield takeEvery(ActionTypes.FETCH_SINGLE_PRODUCT, singleProduct);
  yield takeEvery(ActionTypes.CREATE_PRODUCT, _createProduct);
  yield takeEvery(ActionTypes.UPDATE_PRODUCT, _updateProduct);
  yield takeEvery(ActionTypes.RESET_PRODUCT, _resetProduct);
  yield takeEvery(ActionTypes.FETCH_SKU_WISE, skuWise);
}

export default watchProduct;
