import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
// import { APICore } from 'helpers/api/apiCore';
import { fetchEnquiry, fetchSingleEnquiry, createEnquiry, updateEnquiry, fetchEnquiryStatus, fetchRevenueChartData, fetchEnquiryForDasbord } from "helpers";
import { enquiryApiResponse, apiResponseError } from "./actions";
import { ActionTypes } from "./constants";
import { createEnquiryForm, getEnquiryForm } from "helpers/api/enquiry";

// const api = new APICore();

function* enquiry(params: any): SagaIterator {
  try {
    const response = yield call(fetchEnquiry, { filter: params.payload.data.userDetail, page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload.data.searchBy });
    const enquiryData = response.data;
    yield put(enquiryApiResponse(ActionTypes.FETCH_ENQUIRY, enquiryData));
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.FETCH_ENQUIRY, error));
  }
}

function* _dashboardEnquiryData(params: any): SagaIterator {
  try {
    const response = yield call(fetchEnquiryForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
    const enquiryData = yield response.data;
    yield put(enquiryApiResponse(ActionTypes.DASHBOARD_ENQUIRY_DATA, enquiryData));
  } catch (e: any) {
    yield put(apiResponseError(ActionTypes.DASHBOARD_ENQUIRY_DATA, e))
  }
}

function* singleEnquiry(params: any): SagaIterator {
  try {
    const response = yield call(fetchSingleEnquiry, params.payload.id);
    const enquiryData = response.data
    yield put(enquiryApiResponse(ActionTypes.FETCH_SINGLE_ENQUIRY, enquiryData))
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.FETCH_SINGLE_ENQUIRY, error));
  }

}

function* _createEnquiry({ payload }: any): SagaIterator {
  try {
    const response = yield call(createEnquiry, { enquiry: payload.data });
    const enquiryData = response.data
    yield put(enquiryApiResponse(ActionTypes.CREATE_ENQUIRY_SUCCESS, enquiryData))
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.CREATE_ENQUIRY_SUCCESS, error));
  }

}

function* __createEnquiryForm({ payload }: any): SagaIterator {
  try {
    const response = yield call(createEnquiryForm, payload.data);
    const fileData = response.data
    yield put(enquiryApiResponse(ActionTypes.CREATE_ENQUIRY_SUCCESS_FORM, fileData))
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.CREATE_ENQUIRY_SUCCESS_FORM, error));
  }
}

function* __getEnquiryForm({ payload }: any): SagaIterator {
  try {
    const response = yield call(getEnquiryForm, payload.data);
    const data = response.data
    yield put(enquiryApiResponse(ActionTypes.SET_ENQUIRY_FORM, data))
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.SET_ENQUIRY_FORM, error));
  }
}
function* _updateEnquiry({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateEnquiry, { id: payload.data.id, updateBody: payload.data.body });
    const enquiryData = response.data
    yield put(enquiryApiResponse(ActionTypes.UPDATE_ENQUIRY_SUCCESS, enquiryData))
  } catch (error: any) {
    yield put(apiResponseError(ActionTypes.UPDATE_ENQUIRY_SUCCESS, error));
  }
}

function* _fetchEnquiryStatuses(): SagaIterator {
  try {
    const response = yield call(fetchEnquiryStatus);
    const productData = response.data
    yield put(enquiryApiResponse(ActionTypes.SET_ENQUIRY_STATUSES, productData))
  } catch (error: any) {
    yield put(enquiryApiResponse(ActionTypes.SET_ENQUIRY_STATUSES, error));
  }
}


function* _fetchRevenueChartData(params: any): SagaIterator {
  try {
    const response = yield call(fetchRevenueChartData, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
    const chartData = response.data
    yield put(enquiryApiResponse(ActionTypes.GET_REVENUE_CHART_DATA, chartData))
  } catch (error: any) {
    yield put(enquiryApiResponse(ActionTypes.GET_REVENUE_CHART_DATA, error));
  }
}






export function* watchEnquiry() {
  yield takeEvery(ActionTypes.FETCH_ENQUIRY, enquiry);
  yield takeEvery(ActionTypes.FETCH_SINGLE_ENQUIRY, singleEnquiry);
  yield takeEvery(ActionTypes.CREATE_ENQUIRY_FORM, __createEnquiryForm);
  yield takeEvery(ActionTypes.CREATE_ENQUIRY, _createEnquiry);
  yield takeEvery(ActionTypes.UPDATE_ENQUIRY, _updateEnquiry);
  yield takeEvery(ActionTypes.FETCH_ENQUIRY_STATUSES, _fetchEnquiryStatuses)
  yield takeEvery(ActionTypes.GET_ENQUIRY_FORM, __getEnquiryForm);
  yield takeEvery(ActionTypes.GET_REVENUE_CHART_DATA, _fetchRevenueChartData)
  yield takeEvery(ActionTypes.DASHBOARD_ENQUIRY_DATA, _dashboardEnquiryData)
}

export default watchEnquiry;
