import { all } from "redux-saga/effects";
import watchEmployee from "./employee/saga";
import { watchEnquiry } from "./enquiry/saga";
import { watchTask } from "./task/saga";
import layoutSaga from "./layout/saga";
import { watchSendOtpRequest, watchVerifyOtpRequest,watchVerifyCustomerSession } from "./login/saga";
import watchTeam from "./team/saga";
import watchTransporter from "./transporter/saga";
import watchVendor from "./vendor/saga";
import watchProduct from "./product/saga";
import watchForm from "./form/saga";
import watchRole from "./role/saga";
import watchApproval from "./approval/saga";
import watchProjectTask from "./project-management/saga";
import watchCustomer from "./customer/saga";
import watchQuery from "./query/saga";
import watchFiles from "./files/saga";
import watchTag from "./tag/saga";
import watchChat from "./chat/saga";
import watchLeads from "./lead/saga";
import watchNotification from "./notification/saga";


export default function* rootSaga() {
  yield all([
    watchEnquiry(),
    watchTask(),
    layoutSaga(),
    watchSendOtpRequest(),
    watchVerifyOtpRequest(),
    watchVendor(),
    watchTransporter(),
    watchTeam(),
    watchEmployee(),
    watchProduct(),
    watchForm(),
    watchRole(),
    watchApproval(),
    watchProjectTask(),
    watchCustomer(),
    watchQuery(),
    watchFiles(),
    watchTag(),
    watchChat(),
    watchLeads(),
    watchNotification(),
    watchVerifyCustomerSession()
  ]);
}
