import { combineReducers } from "redux";
import Enquiry from "./enquiry/reducers";
import Task from "./task/reducers";
import Layout from "./layout/reducers";
import Auth from "./login/reducers";
import TransporterReducer from "./transporter/reducers";
import VendorReducer from "./vendor/reducers"
import TeamReducer from "./team/reducers";
import EmployeeReducer from "./employee/reducers";
import Product from "./product/reducers";
import FormReducer from "./form/reducers";
import RoleReducer from "./role/reducers";
import ApprovalReducer from "./approval/reducers";
import ProjectTaskReducer from './project-management/reducers';
import CustomerReducer from "./customer/reducers"
import QueryReducer from "./query/reducers"
import FilesReducer from "./files/reducers";
import TagReducer from "./tag/reducers";
import ChatReducer from "./chat/reducers";
import LeadReducer from './lead/reducers'
import NotificationReducer from "./notification/reducers";


export default combineReducers({
  Enquiry,
  Task,
  Layout,
  Auth,
  VendorReducer,
  TransporterReducer,
  TeamReducer,
  EmployeeReducer,
  Product,
  FormReducer,
  RoleReducer,
  ApprovalReducer,
  ProjectTaskReducer,
  CustomerReducer,
  QueryReducer,
  FilesReducer,
  TagReducer,
  ChatReducer,
  LeadReducer,
  NotificationReducer
});
