import { ActionTypes as AuthActionTypes } from "./constants";

const INIT_STATE = {
    loading: true,
    error: {},
    query: {},
    singleQuery: {},
    createdQuery: {},
    success: false
}


type AuthActionType = {
    type:
    | AuthActionTypes.FETCH_QUERY
    | AuthActionTypes.SET_QUERY
    | AuthActionTypes.SET_ERROR
    | AuthActionTypes.FETCH_SINGLE_QUERY
    | AuthActionTypes.SET_SINGLE_QUERY
    | AuthActionTypes.SET_SINGLE_ERROR
    | AuthActionTypes.UPDATE_QUERY
    | AuthActionTypes.UPDATE_QUERY_ERROR
    | AuthActionTypes.UPDATE_QUERY_SUCCESS
    | AuthActionTypes.CREATE_QUERY_SUCCESS
    | AuthActionTypes.RESET
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}


const QueryReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case AuthActionTypes.SET_QUERY:
            return { ...state, query: action.payload.data, loading: false, success: false, singleQuery: {}, createdQuery: {} }
        case AuthActionTypes.SET_SINGLE_QUERY:
            return { ...state, singleQuery: action.payload.data, loading: false, success: false }
        case AuthActionTypes.CREATE_QUERY_SUCCESS:
            return { ...state, createdQuery: action.payload.data, loading: false, success: true }
        case AuthActionTypes.UPDATE_QUERY_SUCCESS:
            return { ...state, loading: false, success: true }
        case AuthActionTypes.SET_ERROR:
            return { ...state, error: action.payload.error, loading: false, success: false }
        case AuthActionTypes.RESET:
            return { ...state, loading: false, success: false, singleQuery: {}, createdQuery: {} }
        default:
            return { ...state }
    }
}

export default QueryReducer;