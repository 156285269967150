import { ActionTypes } from './constant';

const INIT_STATE = {
    loading: true,
    lead: {},
    singleLead: {},
    error: {},
    createdLead: {},
    leadStatus: {},
    success: false
}


type AuthActionType = {
    type:
    | ActionTypes.SET_LEAD
    | ActionTypes.CREATE_LEAD
    | ActionTypes.FETCH_LEAD
    | ActionTypes.SET_ERROR
    | ActionTypes.FETCH_SINGLE_LEAD
    | ActionTypes.SET_SINGLE_LEAD
    | ActionTypes.UPDATE_LEAD_SUCCESS
    | ActionTypes.UPDATE_LEAD_FAIL
    | ActionTypes.SET_LEAD_TAG
    | ActionTypes.CREATE_LEAD_SUCCESS
    | ActionTypes.CREATE_LEAD_FAIL
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    }
}

const LeadReducer = (state: any = INIT_STATE, action: AuthActionType) => {
    switch (action.type) {
        case ActionTypes.SET_LEAD:
            return { ...state, lead: action.payload.data, loading: false, success: false, singleLead: {}, error: {} };
        case ActionTypes.SET_SINGLE_LEAD:
            return { ...state, singleLead: action.payload.data, loading: false, success: false, error: {} };
        case ActionTypes.SET_LEAD_TAG:
            return { ...state, leadStatus: action.payload.data, loading: false, success: false }
        case ActionTypes.CREATE_LEAD_SUCCESS:
            return { ...state, success: true, error: {}, createdLead: action.payload.data }
        case ActionTypes.CREATE_LEAD_FAIL:
            return { ...state, success: false, error: action.payload.data }
        case ActionTypes.UPDATE_LEAD_SUCCESS:
            return { ...state, success: true, error: {} }
        case ActionTypes.UPDATE_LEAD_FAIL:
            return { ...state, success: false, error: action.payload.data }
        case ActionTypes.SET_ERROR:
            return { ...state, lead: {}, error: action.payload.error }
        default:
            return { ...state }
    }
}

export default LeadReducer;