import { VendorResponse } from "redux/types/DataTypes";
import { ActionTypes } from './constants';

export type VendorActionType = {
    type:
    | ActionTypes.CREATE_VENDOR
    | ActionTypes.FETCH_VENDOR
    | ActionTypes.SET_VENDOR
    | ActionTypes.SET_ERROR
    | ActionTypes.UPDATE_VENDOR
    | ActionTypes.FETCH_SINGLE_VENDOR
    | ActionTypes.SET_SINGLE_VENDOR
    | ActionTypes.UPDATE_VENDOR_SUCCESS
    | ActionTypes.UPDATE_VENDOR_FAIL
    | ActionTypes.ADD_VENDOR_SUCCESS
    | ActionTypes.ADD_VENDOR_FAIL
    | ActionTypes.DASHBOARD_VENDOR_DATA
    | ActionTypes.SET_DASHBOARD_VENDOR_DATA
    payload: {} | string;
}

export const getVendor = (data: any): VendorActionType => ({
    type: ActionTypes.FETCH_VENDOR,
    payload: { data }
})

export const getSingleVendor = (id: any): VendorActionType => ({
    type: ActionTypes.FETCH_SINGLE_VENDOR,
    payload: { id }
})

export const vendorDashbordData = (data: any) => ({
    type: ActionTypes.DASHBOARD_VENDOR_DATA,
    payload: { data }
})


export const createVendor = (data: any): VendorActionType => ({
    type: ActionTypes.CREATE_VENDOR,
    payload: { data }
})

export const updateVendor = (data: any): VendorActionType => ({
    type: ActionTypes.UPDATE_VENDOR,
    payload: { data }
})

export const vendorReset = () => ({
    type: ActionTypes.RESET_VENDOR,
    payload: {  }
})

export const vendorApiResponse = (actionType: string, data: VendorResponse): VendorActionType => {
    // type: ActionTypes.SET_VENDOR,
    // payload: { data }
    switch (actionType) {
        case ActionTypes.FETCH_VENDOR:
            return {
                type: ActionTypes.SET_VENDOR,
                payload: { data }
            }
        case ActionTypes.FETCH_SINGLE_VENDOR:
            return {
                type: ActionTypes.SET_SINGLE_VENDOR,
                payload: { data }
            }
        case ActionTypes.DASHBOARD_VENDOR_DATA:
            return {
                type: ActionTypes.SET_DASHBOARD_VENDOR_DATA,
                payload: { data }
            }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };
    }
}

export const vendorUpdateResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_SUCCESS,
        payload: { data }
    }
}

export const vendorUpdateError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.UPDATE_VENDOR_FAIL,
        payload: { actionType, error }
    }
}


export const vendorAddResponse = (actionType: string, data: any) => {
    return {
        type: ActionTypes.ADD_VENDOR_SUCCESS,
        payload: { data }
    }
}


export const vendorAddError = (actionType: string, error: any) => {
    return {
        type: ActionTypes.ADD_VENDOR_FAIL,
        payload: { actionType, error }
    }
}


export const vendorApiError = (actionType: string, error: any): VendorActionType => ({
    type: ActionTypes.SET_ERROR,
    payload: { actionType, error }
})