import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchCustomer, fetchSingleCustomer, createCustomer, updateCustomer, fetchCustomerForDasbord } from "helpers";
import { ActionTypes } from './constants'
import { customerAddError, customerAddResponse, customerApiError, customerApiResponse, customerUpdateError, customerUpdateResponse } from "./actions";


function* customer(params: any): SagaIterator {
    try {
        const response = yield call(fetchCustomer, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, filterBy: params.payload.data.filterBy, searchBy: params.payload?.data?.searchBy });
        const customerData = yield response.data;
        yield put(customerApiResponse(ActionTypes.FETCH_CUSTOMER, customerData));
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.FETCH_CUSTOMER, e))
    }
}



function* _dashboardData(params: any): SagaIterator {
    try {
        const response = yield call(fetchCustomerForDasbord, { filterBy: params.payload.data?.filterBy, filter: params.payload.data?.filter });
        const customerData = yield response.data;
        yield put(customerApiResponse(ActionTypes.DASHBOARD_CUSTOMER_DATA, customerData));
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.DASHBOARD_CUSTOMER_DATA, e))
    }
}


function* singleCustomer(params: any): SagaIterator {
    try {
        const response = yield call(fetchSingleCustomer, params.payload.id);
        const singleCustomer = response.data;
        yield put(customerApiResponse(ActionTypes.FETCH_SINGLE_CUSTOMER, singleCustomer))
    } catch (e: any) {
        yield put(customerApiError(ActionTypes.FETCH_SINGLE_CUSTOMER, e));
    }
}


function* _createCustomer({ payload }: any): SagaIterator {
    try {
        const response = yield call(createCustomer, { customer: payload.data })
        const customerData = response.data;
        yield put(customerAddResponse(ActionTypes.CREATE_CUSTOMER, customerData))
    } catch (e: any) {
        yield put(customerAddError(ActionTypes.CREATE_CUSTOMER, e));
    }
}

function* _updateCustomer({ payload }: any): SagaIterator {
    try {
        const response = yield call(updateCustomer, { id: payload.data.id, updateBody: payload.data.body })
        const customerData = response.data;
        yield put(customerUpdateResponse(ActionTypes.UPDATE_CUSTOMER, customerData));
    } catch (e: any) {
        yield put(customerUpdateError(ActionTypes.UPDATE_CUSTOMER, e));
    }
}



function* watchCustomer() {
    yield takeEvery(ActionTypes.FETCH_CUSTOMER, customer);
    yield takeEvery(ActionTypes.CREATE_CUSTOMER, _createCustomer);
    yield takeEvery(ActionTypes.UPDATE_CUSTOMER, _updateCustomer);
    yield takeEvery(ActionTypes.FETCH_SINGLE_CUSTOMER, singleCustomer);
    yield takeEvery(ActionTypes.DASHBOARD_CUSTOMER_DATA, _dashboardData)

}

export default watchCustomer;