// import { APICore } from 'helpers/api/apiCore';
import { ActionTypes as AuthActionTypes } from "./constants";
import { ProductState } from "../types/DataTypes";

// const api = new APICore();

const INIT_STATE = {
  loading: true,
  product: {},
  error: {},
  success: false,
  updateSuccess: false,
  singleProduct: {},
  newlyAddedProduct:{},
  skuData: {}
};

type AuthActionType = {
  type: AuthActionTypes.SET_PRODUCT | AuthActionTypes.SET_ERROR | AuthActionTypes.SET_SINGLE_PRODUCT |
  AuthActionTypes.UPDATE_PRODUCT | AuthActionTypes.UPDATE_PRODUCT_SUCCESS | AuthActionTypes.CREATE_PRODUCT_SUCCESS | AuthActionTypes.UPDATE_PRODUCT_FAILURE | AuthActionTypes.RESET_PRODUCT
  | AuthActionTypes.SET_SKU_WISE
  payload: {
    actionType?: string;
    data?: {};
    error?: string;
  };
};

const ProductReducer = (state: ProductState = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.SET_PRODUCT:
      return { ...state, product: action.payload, loading: false };
    case AuthActionTypes.SET_SINGLE_PRODUCT:
      return { ...state, singleProduct: action.payload, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.UPDATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, updateSuccess: true, success: false };
    case AuthActionTypes.CREATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, success: true, updateSuccess: false,newlyAddedProduct:action.payload.data };
    case AuthActionTypes.RESET_PRODUCT:
      return { ...state, singleProduct: {}, error: action.payload, loading: false, updateSuccess: false, success: false };
    case AuthActionTypes.SET_ERROR:
      return { ...state, singleProduct: {}, error: action.payload, loading: false };
    case AuthActionTypes.SET_SKU_WISE:
      return { ...state, skuData: action.payload.data, loading: false, updateSuccess: false, success: false }
    default:
      return { ...state };
  }
};

export default ProductReducer;
