import { ActionTypes } from "./constants";

export function createTag(tag:any){
    return ({
        type:ActionTypes.CREATE_TAG,
        payload:{tag}
    })
}

export const getAllTag = (data: any): any => ({
        type: ActionTypes.FETCH_ALL_TAG,
        payload: { data },
      });

export function getSingleTag(tagId:any){
    return ({
        type:ActionTypes.FETCH_SINGLE_TAG,
        payload:{tagId}
    })
}

export function updateTag(tag:any,tagId:any){
    return ({
        type:ActionTypes.UPDATE_TAG,
        payload:{tag,tagId}
    })
}

export function getTagType(){
    return ({
        type:ActionTypes.GET_TAG_TYPE,
        payload:{}
    })
}

//handle response after saga
export const tagApiResponse = (actionType: string, data: any) => {
    switch (actionType) {
        case ActionTypes.CREATE_TAG:
            return {
                    type: ActionTypes.SAVE_TAG,
                    payload: { data }
            }
            case ActionTypes.FETCH_ALL_TAG:
                return {
                        type: ActionTypes.SET_TAG,
                        payload: { data }
                }
            case ActionTypes.FETCH_SINGLE_TAG:
                    return {
                            type: ActionTypes.SET_SINGLE_TAG,
                            payload: { data }
                }
            case ActionTypes.GET_TAG_TYPE:
                return{
                    type:ActionTypes.SET_TAG_TYPE,
                    payload:{data}
                }
            case ActionTypes.UPDATE_TAG:
                    return {
                            type: ActionTypes.SET_SINGLE_TAG,
                            payload: { data }
                }
        default:
            return {
                type: ActionTypes.SET_ERROR,
                payload: { actionType, error: "Error While setting data to store ! " },
            };

    }
}