import { SagaIterator } from "@redux-saga/core";
// import { APICore } from "helpers/api/apiCore";
import { call, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./constants";
import { createTransporter, createTransporterWithFiles, fetchSingleTransporter, fetchTransporter, updateTransporter, updateTransporterWithFiles } from "helpers";
import { transporterAddError, transporterAddResponse, transporterApiError, transporterApiResponse, transporterUpdateError, transporterUpdateResponse } from "./actions";

function* transporter(params: any): SagaIterator {
   try {
      const response = yield call(fetchTransporter, { page: params.payload.data.page, limit: params.payload.data.limit, sortBy: params.payload.data.sortBy, sort: params.payload.data.sort, searchBy: params.payload.data.searchBy });
      const transporterData = response.data;
      // console.log(transporterData);
      yield put(transporterApiResponse(ActionTypes.FETCH_TRANSPORTER, transporterData));
   } catch (e: any) {
      yield put(transporterApiError(ActionTypes.FETCH_TRANSPORTER, e));
   }
}

function* singleTransporter(params: any): SagaIterator {
   try {
      const response = yield call(fetchSingleTransporter, params.payload.id);
      const singleTransporter = response.data;
      yield put(transporterApiResponse(ActionTypes.FETCH_SINGLE_TRANSPORTER, singleTransporter))
   } catch (e: any) {
      yield put(transporterApiError(ActionTypes.FETCH_SINGLE_TRANSPORTER, e))
   }
}

function* _createTransporter({ payload }: any): SagaIterator {
   try {
      const response = yield call(createTransporter, { transporter: payload.data })
      const transporterData = response.data;
      yield put(transporterAddResponse(ActionTypes.CREATE_TRANSPORTER, transporterData))
   } catch (e: any) {
      yield put(transporterAddError(ActionTypes.CREATE_TRANSPORTER, e))
   }
}

function* _updateTransporter({ payload }: any): SagaIterator {
   try {
      const response = yield call(updateTransporter, { id: payload.data.id, updateBody: payload.data.body })
      const transporterData = response.data;
      // console.log("Updated successfully", transporterData);
      yield put(transporterUpdateResponse(ActionTypes.UPDATE_TRANSPORTER, transporterData))
   } catch (err: any) {
      yield put(transporterUpdateError(ActionTypes.UPDATE_TRANSPORTER, err))
   }
}


//! dekleter

function* _createTransporterWithFiles({ payload }: any): SagaIterator {
   try {
      const response = yield call(createTransporterWithFiles, { transporterData: payload.data.transporter, chequeData: payload.data.chequeData, agreementData: payload.data.agreementData })
      const transporterData = response.data;
      console.log("sagatry", transporterData)
      yield put(transporterAddResponse(ActionTypes.CREATE_TRANSPORTER, transporterData))
   } catch (e: any) {
      console.log(e, "sagatryE")
      yield put(transporterAddError(ActionTypes.CREATE_TRANSPORTER, e))
   }
}

function* _updateTransporterWithFiles({ payload }: any): SagaIterator {
   try {
      const response = yield call(updateTransporterWithFiles, { id: payload.data.id, updateBody: payload.data.body, chequeData: payload.data.chequeData, agreementData: payload.data.agreementData })
      const transporterData = response.data;
      // console.log("Updated successfully", transporterData);
      yield put(transporterUpdateResponse(ActionTypes.UPDATE_TRANSPORTER, transporterData))
   } catch (err: any) {
      yield put(transporterUpdateError(ActionTypes.UPDATE_TRANSPORTER, err))
   }
}


export function* watchTransporter() {
   yield takeEvery(ActionTypes.FETCH_TRANSPORTER, transporter);
   yield takeEvery(ActionTypes.FETCH_SINGLE_TRANSPORTER, singleTransporter);
   yield takeEvery(ActionTypes.UPDATE_TRANSPORTER, _updateTransporter);
   yield takeEvery(ActionTypes.CREATE_TRANSPORTER, _createTransporter);

   yield takeEvery(ActionTypes.CREATE_TRANSPORTER_WITH_FILES, _createTransporterWithFiles);
   yield takeEvery(ActionTypes.UPDATE_TRANSPORTER_WITH_FILES, _updateTransporterWithFiles);
}

export default watchTransporter;