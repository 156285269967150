import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ActionTypes } from './constants';
import { fetchNotification } from "helpers"
import { notificationApiError, notificationApiResponse } from "./actions";

function* notification(params: any): SagaIterator {
    try {
        console.log("paramsnotifi",params)
        const response = yield call(fetchNotification, { userId: params.payload.data.userId, userType: params.payload.data.userType, typeId: params.payload.data.typeId });
        const notificationData = response.data;
        // console.log("notificationData", notificationData)
        yield put(notificationApiResponse(ActionTypes.FETCH_NOTIFICATIONS, notificationData));

    } catch (error: any) {
        yield put(notificationApiError(ActionTypes.FETCH_NOTIFICATIONS, error));
    }
}




export function* watchNotification() {
    yield takeEvery(ActionTypes.FETCH_NOTIFICATIONS, notification);

}

export default watchNotification;
