import { APICore } from "./apiCore";

const api = new APICore();

function fetchVendor(params: { page: string, limit: string, sortBy: string, sort: string, filterBy?: any, searchBy?: string }) {
    if (params.sortBy.split(' ').length > 1) {
        params.sortBy = params.sortBy.split(' ').join('-')
    }
    const baseUrl = `/vendor?page=${params.page}&limit=${params.limit}&sortBy=${params.sortBy}&sort=${params.sort}&filterBy=${params.filterBy}&searchBy=${params?.searchBy ? params?.searchBy : ''}`;
    return api.get(`${baseUrl}`, '');
}

function fetchVendorForDasbord(params: { filterBy?: any, filter?: any }) {
    const baseUrl = `/vendor/dashboard/data?filterBy=${params.filterBy}&filter=${params.filter}`;
    return api.create(`${baseUrl}`, "");
}


function fetchSingleVendor(params: any) {
    const baseUrl = `/vendor/${params}`;
    return api.get(`${baseUrl}`, {});
}

function createVendor(body: any) {
    const baseUrl = "/vendor";
    return api.create(`${baseUrl}`, body);
}

function updateVendor(data: any) {
    const baseUrl = `/vendor/${data.id}`;
    return api.update(`${baseUrl}`, { vendor: data.updateBody });
}



export { fetchVendor, createVendor, updateVendor, fetchSingleVendor, fetchVendorForDasbord };